import React from "react";
import BuildingBold from "./buildingBold";
import CaseStudies from "./caseStudies";
import OurTeam from "./ourTeam";
import RunningLine from "./runningLine";
import Services from "./services";
import TrustedBy from "./trustedBy";
import Reviews from "./reviews";
import LetsTalkComponent from "components/letsTalkComponent";
import Seo from "components/seo";

function Main({
  seo,
  buildingBold,
  services,
  runningLine,
  caseStudies,
  trustedBy,
  ourTeam,
  reviews,
  letsTalk,
}) {
  return (
    <>
      <Seo {...seo} />
      <BuildingBold {...buildingBold} />
      <Services {...services} />
      <RunningLine {...runningLine} />
      <CaseStudies {...caseStudies} />
      <TrustedBy {...trustedBy} />
      <OurTeam {...ourTeam} />
      <Reviews {...reviews} />
      <LetsTalkComponent {...letsTalk} />
    </>
  );
}

export default Main;
