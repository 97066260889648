// extracted by mini-css-extract-plugin
export var images = "ourTeam-module--images--8f427";
export var imgContainer = "ourTeam-module--imgContainer--0eb21";
export var ma0 = "ourTeam-module--ma-0--39128";
export var ma10 = "ourTeam-module--ma-10--42071";
export var ma100 = "ourTeam-module--ma-100--ae850";
export var ma105 = "ourTeam-module--ma-105--1b86b";
export var ma110 = "ourTeam-module--ma-110--71863";
export var ma115 = "ourTeam-module--ma-115--53e95";
export var ma12 = "ourTeam-module--ma-12--829ec";
export var ma120 = "ourTeam-module--ma-120--289dd";
export var ma130 = "ourTeam-module--ma-130--0182c";
export var ma140 = "ourTeam-module--ma-140--47b57";
export var ma15 = "ourTeam-module--ma-15--47456";
export var ma150 = "ourTeam-module--ma-150--1a47a";
export var ma16 = "ourTeam-module--ma-16--c64f7";
export var ma18 = "ourTeam-module--ma-18--27fb4";
export var ma185 = "ourTeam-module--ma-185--1f165";
export var ma20 = "ourTeam-module--ma-20--e6b7d";
export var ma25 = "ourTeam-module--ma-25--afd59";
export var ma30 = "ourTeam-module--ma-30--8dcc0";
export var ma35 = "ourTeam-module--ma-35--483c3";
export var ma40 = "ourTeam-module--ma-40--50c6f";
export var ma45 = "ourTeam-module--ma-45--16e02";
export var ma48 = "ourTeam-module--ma-48--2292e";
export var ma5 = "ourTeam-module--ma-5--02e63";
export var ma50 = "ourTeam-module--ma-50--63f7c";
export var ma55 = "ourTeam-module--ma-55--037ec";
export var ma60 = "ourTeam-module--ma-60--080a1";
export var ma70 = "ourTeam-module--ma-70--317e4";
export var ma80 = "ourTeam-module--ma-80--7742c";
export var ma85 = "ourTeam-module--ma-85--c0006";
export var ma90 = "ourTeam-module--ma-90--a58bc";
export var ma95 = "ourTeam-module--ma-95--313d6";
export var mb0 = "ourTeam-module--mb-0--55be7";
export var mb10 = "ourTeam-module--mb-10--23471";
export var mb100 = "ourTeam-module--mb-100--0c76b";
export var mb105 = "ourTeam-module--mb-105--d900e";
export var mb110 = "ourTeam-module--mb-110--224d4";
export var mb115 = "ourTeam-module--mb-115--85e73";
export var mb12 = "ourTeam-module--mb-12--90690";
export var mb120 = "ourTeam-module--mb-120--43b0e";
export var mb130 = "ourTeam-module--mb-130--e8684";
export var mb140 = "ourTeam-module--mb-140--29960";
export var mb15 = "ourTeam-module--mb-15--8becc";
export var mb150 = "ourTeam-module--mb-150--17180";
export var mb16 = "ourTeam-module--mb-16--e0bfc";
export var mb18 = "ourTeam-module--mb-18--72c36";
export var mb185 = "ourTeam-module--mb-185--1fc5f";
export var mb20 = "ourTeam-module--mb-20--63a40";
export var mb25 = "ourTeam-module--mb-25--58493";
export var mb30 = "ourTeam-module--mb-30--488a1";
export var mb35 = "ourTeam-module--mb-35--68a91";
export var mb40 = "ourTeam-module--mb-40--7741a";
export var mb45 = "ourTeam-module--mb-45--7e92e";
export var mb48 = "ourTeam-module--mb-48--acc67";
export var mb5 = "ourTeam-module--mb-5--580db";
export var mb50 = "ourTeam-module--mb-50--62f4f";
export var mb55 = "ourTeam-module--mb-55--c8655";
export var mb60 = "ourTeam-module--mb-60--5c223";
export var mb70 = "ourTeam-module--mb-70--78bbc";
export var mb80 = "ourTeam-module--mb-80--c2e8d";
export var mb85 = "ourTeam-module--mb-85--744ca";
export var mb90 = "ourTeam-module--mb-90--61c74";
export var mb95 = "ourTeam-module--mb-95--b562e";
export var mh0 = "ourTeam-module--mh-0--5e145";
export var mh10 = "ourTeam-module--mh-10--abff3";
export var mh100 = "ourTeam-module--mh-100--08bad";
export var mh105 = "ourTeam-module--mh-105--49034";
export var mh110 = "ourTeam-module--mh-110--ad3fc";
export var mh115 = "ourTeam-module--mh-115--73580";
export var mh12 = "ourTeam-module--mh-12--93748";
export var mh120 = "ourTeam-module--mh-120--649bf";
export var mh130 = "ourTeam-module--mh-130--3b947";
export var mh140 = "ourTeam-module--mh-140--b8408";
export var mh15 = "ourTeam-module--mh-15--259a0";
export var mh150 = "ourTeam-module--mh-150--e9179";
export var mh16 = "ourTeam-module--mh-16--8b1f8";
export var mh18 = "ourTeam-module--mh-18--67644";
export var mh185 = "ourTeam-module--mh-185--7441d";
export var mh20 = "ourTeam-module--mh-20--68238";
export var mh25 = "ourTeam-module--mh-25--13475";
export var mh30 = "ourTeam-module--mh-30--7cd8e";
export var mh35 = "ourTeam-module--mh-35--ffa70";
export var mh40 = "ourTeam-module--mh-40--d1a05";
export var mh45 = "ourTeam-module--mh-45--0e01b";
export var mh48 = "ourTeam-module--mh-48--82e37";
export var mh5 = "ourTeam-module--mh-5--f3ff2";
export var mh50 = "ourTeam-module--mh-50--8020c";
export var mh55 = "ourTeam-module--mh-55--1404c";
export var mh60 = "ourTeam-module--mh-60--10ece";
export var mh70 = "ourTeam-module--mh-70--16e4b";
export var mh80 = "ourTeam-module--mh-80--86913";
export var mh85 = "ourTeam-module--mh-85--aef12";
export var mh90 = "ourTeam-module--mh-90--fe58d";
export var mh95 = "ourTeam-module--mh-95--abf4f";
export var ml0 = "ourTeam-module--ml-0--03dcb";
export var ml10 = "ourTeam-module--ml-10--497fe";
export var ml100 = "ourTeam-module--ml-100--77b88";
export var ml105 = "ourTeam-module--ml-105--6b77e";
export var ml110 = "ourTeam-module--ml-110--0b804";
export var ml115 = "ourTeam-module--ml-115--8a930";
export var ml12 = "ourTeam-module--ml-12--69c9e";
export var ml120 = "ourTeam-module--ml-120--6e3b3";
export var ml130 = "ourTeam-module--ml-130--3e17b";
export var ml140 = "ourTeam-module--ml-140--81c96";
export var ml15 = "ourTeam-module--ml-15--ab483";
export var ml150 = "ourTeam-module--ml-150--1bd0b";
export var ml16 = "ourTeam-module--ml-16--5b0f4";
export var ml18 = "ourTeam-module--ml-18--d3a7b";
export var ml185 = "ourTeam-module--ml-185--6fe11";
export var ml20 = "ourTeam-module--ml-20--85c7e";
export var ml25 = "ourTeam-module--ml-25--03cd4";
export var ml30 = "ourTeam-module--ml-30--241f0";
export var ml35 = "ourTeam-module--ml-35--3f3af";
export var ml40 = "ourTeam-module--ml-40--ac584";
export var ml45 = "ourTeam-module--ml-45--1fc71";
export var ml48 = "ourTeam-module--ml-48--96de5";
export var ml5 = "ourTeam-module--ml-5--85544";
export var ml50 = "ourTeam-module--ml-50--82b8c";
export var ml55 = "ourTeam-module--ml-55--02458";
export var ml60 = "ourTeam-module--ml-60--abd13";
export var ml70 = "ourTeam-module--ml-70--f37c4";
export var ml80 = "ourTeam-module--ml-80--ee7ce";
export var ml85 = "ourTeam-module--ml-85--0762a";
export var ml90 = "ourTeam-module--ml-90--5b26d";
export var ml95 = "ourTeam-module--ml-95--7d79f";
export var mr0 = "ourTeam-module--mr-0--02fb5";
export var mr10 = "ourTeam-module--mr-10--445f4";
export var mr100 = "ourTeam-module--mr-100--cb460";
export var mr105 = "ourTeam-module--mr-105--f669c";
export var mr110 = "ourTeam-module--mr-110--5feb6";
export var mr115 = "ourTeam-module--mr-115--0aebe";
export var mr12 = "ourTeam-module--mr-12--d8441";
export var mr120 = "ourTeam-module--mr-120--3070c";
export var mr130 = "ourTeam-module--mr-130--18830";
export var mr140 = "ourTeam-module--mr-140--7aa3e";
export var mr15 = "ourTeam-module--mr-15--a38fa";
export var mr150 = "ourTeam-module--mr-150--6ae34";
export var mr16 = "ourTeam-module--mr-16--e46dc";
export var mr18 = "ourTeam-module--mr-18--0f463";
export var mr185 = "ourTeam-module--mr-185--8776b";
export var mr20 = "ourTeam-module--mr-20--295c4";
export var mr25 = "ourTeam-module--mr-25--78a89";
export var mr30 = "ourTeam-module--mr-30--6f313";
export var mr35 = "ourTeam-module--mr-35--df7ef";
export var mr40 = "ourTeam-module--mr-40--2bbe3";
export var mr45 = "ourTeam-module--mr-45--e44f2";
export var mr48 = "ourTeam-module--mr-48--bf51f";
export var mr5 = "ourTeam-module--mr-5--e3089";
export var mr50 = "ourTeam-module--mr-50--0f801";
export var mr55 = "ourTeam-module--mr-55--fce74";
export var mr60 = "ourTeam-module--mr-60--fba9c";
export var mr70 = "ourTeam-module--mr-70--8854f";
export var mr80 = "ourTeam-module--mr-80--38d86";
export var mr85 = "ourTeam-module--mr-85--5d6b9";
export var mr90 = "ourTeam-module--mr-90--889e3";
export var mr95 = "ourTeam-module--mr-95--bfa0f";
export var mt0 = "ourTeam-module--mt-0--8992d";
export var mt10 = "ourTeam-module--mt-10--4af22";
export var mt100 = "ourTeam-module--mt-100--e3677";
export var mt105 = "ourTeam-module--mt-105--cb6c9";
export var mt110 = "ourTeam-module--mt-110--2abef";
export var mt115 = "ourTeam-module--mt-115--52b86";
export var mt12 = "ourTeam-module--mt-12--b2a56";
export var mt120 = "ourTeam-module--mt-120--cb42f";
export var mt130 = "ourTeam-module--mt-130--3e438";
export var mt140 = "ourTeam-module--mt-140--b9199";
export var mt15 = "ourTeam-module--mt-15--2fe04";
export var mt150 = "ourTeam-module--mt-150--55437";
export var mt16 = "ourTeam-module--mt-16--31b78";
export var mt18 = "ourTeam-module--mt-18--d3782";
export var mt185 = "ourTeam-module--mt-185--17eb4";
export var mt20 = "ourTeam-module--mt-20--19097";
export var mt25 = "ourTeam-module--mt-25--8155f";
export var mt30 = "ourTeam-module--mt-30--de785";
export var mt35 = "ourTeam-module--mt-35--20aa3";
export var mt40 = "ourTeam-module--mt-40--6405e";
export var mt45 = "ourTeam-module--mt-45--834ea";
export var mt48 = "ourTeam-module--mt-48--fce04";
export var mt5 = "ourTeam-module--mt-5--9a225";
export var mt50 = "ourTeam-module--mt-50--97247";
export var mt55 = "ourTeam-module--mt-55--d5053";
export var mt60 = "ourTeam-module--mt-60--f5b53";
export var mt70 = "ourTeam-module--mt-70--dd9c0";
export var mt80 = "ourTeam-module--mt-80--d157c";
export var mt85 = "ourTeam-module--mt-85--f4e77";
export var mt90 = "ourTeam-module--mt-90--202a2";
export var mt95 = "ourTeam-module--mt-95--2ad5d";
export var mv0 = "ourTeam-module--mv-0--57bdb";
export var mv10 = "ourTeam-module--mv-10--7f45c";
export var mv100 = "ourTeam-module--mv-100--09c27";
export var mv105 = "ourTeam-module--mv-105--8f4f8";
export var mv110 = "ourTeam-module--mv-110--e4738";
export var mv115 = "ourTeam-module--mv-115--073bb";
export var mv12 = "ourTeam-module--mv-12--a3277";
export var mv120 = "ourTeam-module--mv-120--c8473";
export var mv130 = "ourTeam-module--mv-130--48eab";
export var mv140 = "ourTeam-module--mv-140--1f28a";
export var mv15 = "ourTeam-module--mv-15--b2913";
export var mv150 = "ourTeam-module--mv-150--2c8ac";
export var mv16 = "ourTeam-module--mv-16--1bdae";
export var mv18 = "ourTeam-module--mv-18--509a9";
export var mv185 = "ourTeam-module--mv-185--50248";
export var mv20 = "ourTeam-module--mv-20--be14f";
export var mv25 = "ourTeam-module--mv-25--0882e";
export var mv30 = "ourTeam-module--mv-30--7e0d3";
export var mv35 = "ourTeam-module--mv-35--b4172";
export var mv40 = "ourTeam-module--mv-40--1c712";
export var mv45 = "ourTeam-module--mv-45--b1d00";
export var mv48 = "ourTeam-module--mv-48--4e036";
export var mv5 = "ourTeam-module--mv-5--9d646";
export var mv50 = "ourTeam-module--mv-50--644ae";
export var mv55 = "ourTeam-module--mv-55--b10d3";
export var mv60 = "ourTeam-module--mv-60--0533b";
export var mv70 = "ourTeam-module--mv-70--409ce";
export var mv80 = "ourTeam-module--mv-80--f598b";
export var mv85 = "ourTeam-module--mv-85--f37e7";
export var mv90 = "ourTeam-module--mv-90--e2281";
export var mv95 = "ourTeam-module--mv-95--2c3f0";
export var pa0 = "ourTeam-module--pa-0--d04a4";
export var pa10 = "ourTeam-module--pa-10--6edd9";
export var pa100 = "ourTeam-module--pa-100--c70a1";
export var pa105 = "ourTeam-module--pa-105--9f1c8";
export var pa110 = "ourTeam-module--pa-110--6de40";
export var pa115 = "ourTeam-module--pa-115--67a73";
export var pa12 = "ourTeam-module--pa-12--6578b";
export var pa120 = "ourTeam-module--pa-120--43559";
export var pa130 = "ourTeam-module--pa-130--cf5fd";
export var pa140 = "ourTeam-module--pa-140--82678";
export var pa15 = "ourTeam-module--pa-15--602b2";
export var pa150 = "ourTeam-module--pa-150--0f466";
export var pa16 = "ourTeam-module--pa-16--6c196";
export var pa18 = "ourTeam-module--pa-18--72374";
export var pa185 = "ourTeam-module--pa-185--7d5d0";
export var pa20 = "ourTeam-module--pa-20--1f639";
export var pa25 = "ourTeam-module--pa-25--8f2e0";
export var pa30 = "ourTeam-module--pa-30--ac5fd";
export var pa35 = "ourTeam-module--pa-35--145e1";
export var pa40 = "ourTeam-module--pa-40--b86c7";
export var pa45 = "ourTeam-module--pa-45--fd568";
export var pa48 = "ourTeam-module--pa-48--0d7e0";
export var pa5 = "ourTeam-module--pa-5--74f49";
export var pa50 = "ourTeam-module--pa-50--b0576";
export var pa55 = "ourTeam-module--pa-55--7e7e1";
export var pa60 = "ourTeam-module--pa-60--ca29c";
export var pa70 = "ourTeam-module--pa-70--6981e";
export var pa80 = "ourTeam-module--pa-80--f5af8";
export var pa85 = "ourTeam-module--pa-85--4cabb";
export var pa90 = "ourTeam-module--pa-90--f1685";
export var pa95 = "ourTeam-module--pa-95--ee293";
export var pb0 = "ourTeam-module--pb-0--64d60";
export var pb10 = "ourTeam-module--pb-10--46146";
export var pb100 = "ourTeam-module--pb-100--cd028";
export var pb105 = "ourTeam-module--pb-105--fbb1c";
export var pb110 = "ourTeam-module--pb-110--4c83b";
export var pb115 = "ourTeam-module--pb-115--9d5be";
export var pb12 = "ourTeam-module--pb-12--677e2";
export var pb120 = "ourTeam-module--pb-120--38ab9";
export var pb130 = "ourTeam-module--pb-130--9182c";
export var pb140 = "ourTeam-module--pb-140--f4673";
export var pb15 = "ourTeam-module--pb-15--1d0a2";
export var pb150 = "ourTeam-module--pb-150--e28c9";
export var pb16 = "ourTeam-module--pb-16--6ab13";
export var pb18 = "ourTeam-module--pb-18--01a51";
export var pb185 = "ourTeam-module--pb-185--05fc2";
export var pb20 = "ourTeam-module--pb-20--cd82d";
export var pb25 = "ourTeam-module--pb-25--98e5d";
export var pb30 = "ourTeam-module--pb-30--69a77";
export var pb35 = "ourTeam-module--pb-35--fbf1f";
export var pb40 = "ourTeam-module--pb-40--dd7a2";
export var pb45 = "ourTeam-module--pb-45--849dc";
export var pb48 = "ourTeam-module--pb-48--d748d";
export var pb5 = "ourTeam-module--pb-5--9398b";
export var pb50 = "ourTeam-module--pb-50--9b8e3";
export var pb55 = "ourTeam-module--pb-55--41871";
export var pb60 = "ourTeam-module--pb-60--27b82";
export var pb70 = "ourTeam-module--pb-70--befe4";
export var pb80 = "ourTeam-module--pb-80--ba323";
export var pb85 = "ourTeam-module--pb-85--6fc8e";
export var pb90 = "ourTeam-module--pb-90--8082a";
export var pb95 = "ourTeam-module--pb-95--df3ff";
export var ph0 = "ourTeam-module--ph-0--4c985";
export var ph10 = "ourTeam-module--ph-10--40424";
export var ph100 = "ourTeam-module--ph-100--25685";
export var ph105 = "ourTeam-module--ph-105--c72ba";
export var ph110 = "ourTeam-module--ph-110--e75c5";
export var ph115 = "ourTeam-module--ph-115--f7526";
export var ph12 = "ourTeam-module--ph-12--df369";
export var ph120 = "ourTeam-module--ph-120--34185";
export var ph130 = "ourTeam-module--ph-130--ce2ce";
export var ph140 = "ourTeam-module--ph-140--9a69e";
export var ph15 = "ourTeam-module--ph-15--f2029";
export var ph150 = "ourTeam-module--ph-150--38ae7";
export var ph16 = "ourTeam-module--ph-16--2c83b";
export var ph18 = "ourTeam-module--ph-18--2684c";
export var ph185 = "ourTeam-module--ph-185--40ba0";
export var ph20 = "ourTeam-module--ph-20--940de";
export var ph25 = "ourTeam-module--ph-25--ad5f0";
export var ph30 = "ourTeam-module--ph-30--56065";
export var ph35 = "ourTeam-module--ph-35--043f8";
export var ph40 = "ourTeam-module--ph-40--1c2a9";
export var ph45 = "ourTeam-module--ph-45--62d3a";
export var ph48 = "ourTeam-module--ph-48--7d96c";
export var ph5 = "ourTeam-module--ph-5--b6908";
export var ph50 = "ourTeam-module--ph-50--99b3e";
export var ph55 = "ourTeam-module--ph-55--f76c1";
export var ph60 = "ourTeam-module--ph-60--ac3b0";
export var ph70 = "ourTeam-module--ph-70--31d11";
export var ph80 = "ourTeam-module--ph-80--03600";
export var ph85 = "ourTeam-module--ph-85--c8658";
export var ph90 = "ourTeam-module--ph-90--5d159";
export var ph95 = "ourTeam-module--ph-95--2f3d8";
export var pl0 = "ourTeam-module--pl-0--61a9a";
export var pl10 = "ourTeam-module--pl-10--135e3";
export var pl100 = "ourTeam-module--pl-100--b10d1";
export var pl105 = "ourTeam-module--pl-105--bd97b";
export var pl110 = "ourTeam-module--pl-110--56b66";
export var pl115 = "ourTeam-module--pl-115--344c9";
export var pl12 = "ourTeam-module--pl-12--e4c58";
export var pl120 = "ourTeam-module--pl-120--ce168";
export var pl130 = "ourTeam-module--pl-130--ebc73";
export var pl140 = "ourTeam-module--pl-140--507bf";
export var pl15 = "ourTeam-module--pl-15--d069c";
export var pl150 = "ourTeam-module--pl-150--f67a3";
export var pl16 = "ourTeam-module--pl-16--06a67";
export var pl18 = "ourTeam-module--pl-18--398d3";
export var pl185 = "ourTeam-module--pl-185--3f620";
export var pl20 = "ourTeam-module--pl-20--cfab8";
export var pl25 = "ourTeam-module--pl-25--1a550";
export var pl30 = "ourTeam-module--pl-30--8cb20";
export var pl35 = "ourTeam-module--pl-35--0b357";
export var pl40 = "ourTeam-module--pl-40--3912d";
export var pl45 = "ourTeam-module--pl-45--c5954";
export var pl48 = "ourTeam-module--pl-48--e4fdc";
export var pl5 = "ourTeam-module--pl-5--5396f";
export var pl50 = "ourTeam-module--pl-50--925f1";
export var pl55 = "ourTeam-module--pl-55--21f71";
export var pl60 = "ourTeam-module--pl-60--5eeaa";
export var pl70 = "ourTeam-module--pl-70--964b7";
export var pl80 = "ourTeam-module--pl-80--58e0e";
export var pl85 = "ourTeam-module--pl-85--0f3dd";
export var pl90 = "ourTeam-module--pl-90--4f60f";
export var pl95 = "ourTeam-module--pl-95--c8b28";
export var pr0 = "ourTeam-module--pr-0--e4845";
export var pr10 = "ourTeam-module--pr-10--28d42";
export var pr100 = "ourTeam-module--pr-100--1a121";
export var pr105 = "ourTeam-module--pr-105--2c67d";
export var pr110 = "ourTeam-module--pr-110--73806";
export var pr115 = "ourTeam-module--pr-115--80738";
export var pr12 = "ourTeam-module--pr-12--c4212";
export var pr120 = "ourTeam-module--pr-120--e8fb9";
export var pr130 = "ourTeam-module--pr-130--3e962";
export var pr140 = "ourTeam-module--pr-140--c1b4c";
export var pr15 = "ourTeam-module--pr-15--15e73";
export var pr150 = "ourTeam-module--pr-150--e5fc1";
export var pr16 = "ourTeam-module--pr-16--0b40c";
export var pr18 = "ourTeam-module--pr-18--7a58f";
export var pr185 = "ourTeam-module--pr-185--946c1";
export var pr20 = "ourTeam-module--pr-20--a9bb4";
export var pr25 = "ourTeam-module--pr-25--85cd2";
export var pr30 = "ourTeam-module--pr-30--cdb16";
export var pr35 = "ourTeam-module--pr-35--3adf1";
export var pr40 = "ourTeam-module--pr-40--88c79";
export var pr45 = "ourTeam-module--pr-45--0f76a";
export var pr48 = "ourTeam-module--pr-48--7d065";
export var pr5 = "ourTeam-module--pr-5--4266c";
export var pr50 = "ourTeam-module--pr-50--0423c";
export var pr55 = "ourTeam-module--pr-55--1efd4";
export var pr60 = "ourTeam-module--pr-60--b35b5";
export var pr70 = "ourTeam-module--pr-70--23efc";
export var pr80 = "ourTeam-module--pr-80--b4b15";
export var pr85 = "ourTeam-module--pr-85--0455d";
export var pr90 = "ourTeam-module--pr-90--087c2";
export var pr95 = "ourTeam-module--pr-95--26af0";
export var pt0 = "ourTeam-module--pt-0--ac0f2";
export var pt10 = "ourTeam-module--pt-10--c856c";
export var pt100 = "ourTeam-module--pt-100--dc4b2";
export var pt105 = "ourTeam-module--pt-105--35b76";
export var pt110 = "ourTeam-module--pt-110--0d17f";
export var pt115 = "ourTeam-module--pt-115--fb807";
export var pt12 = "ourTeam-module--pt-12--eb30a";
export var pt120 = "ourTeam-module--pt-120--fed30";
export var pt130 = "ourTeam-module--pt-130--ae922";
export var pt140 = "ourTeam-module--pt-140--1887c";
export var pt15 = "ourTeam-module--pt-15--d1a6a";
export var pt150 = "ourTeam-module--pt-150--20171";
export var pt16 = "ourTeam-module--pt-16--3459a";
export var pt18 = "ourTeam-module--pt-18--d5b9e";
export var pt185 = "ourTeam-module--pt-185--1cb10";
export var pt20 = "ourTeam-module--pt-20--89fd5";
export var pt25 = "ourTeam-module--pt-25--730d4";
export var pt30 = "ourTeam-module--pt-30--4e912";
export var pt35 = "ourTeam-module--pt-35--4fe53";
export var pt40 = "ourTeam-module--pt-40--7a509";
export var pt45 = "ourTeam-module--pt-45--85f00";
export var pt48 = "ourTeam-module--pt-48--84d92";
export var pt5 = "ourTeam-module--pt-5--591f3";
export var pt50 = "ourTeam-module--pt-50--244e7";
export var pt55 = "ourTeam-module--pt-55--cae80";
export var pt60 = "ourTeam-module--pt-60--d3d20";
export var pt70 = "ourTeam-module--pt-70--d7820";
export var pt80 = "ourTeam-module--pt-80--2ad1b";
export var pt85 = "ourTeam-module--pt-85--f726b";
export var pt90 = "ourTeam-module--pt-90--15b81";
export var pt95 = "ourTeam-module--pt-95--ca5bd";
export var pv0 = "ourTeam-module--pv-0--c4b87";
export var pv10 = "ourTeam-module--pv-10--d3b52";
export var pv100 = "ourTeam-module--pv-100--95cfd";
export var pv105 = "ourTeam-module--pv-105--d7691";
export var pv110 = "ourTeam-module--pv-110--16bfa";
export var pv115 = "ourTeam-module--pv-115--9664b";
export var pv12 = "ourTeam-module--pv-12--64f03";
export var pv120 = "ourTeam-module--pv-120--b630a";
export var pv130 = "ourTeam-module--pv-130--8ae43";
export var pv140 = "ourTeam-module--pv-140--75f77";
export var pv15 = "ourTeam-module--pv-15--10e50";
export var pv150 = "ourTeam-module--pv-150--36c85";
export var pv16 = "ourTeam-module--pv-16--9cddb";
export var pv18 = "ourTeam-module--pv-18--8ad4e";
export var pv185 = "ourTeam-module--pv-185--67fac";
export var pv20 = "ourTeam-module--pv-20--51aa1";
export var pv25 = "ourTeam-module--pv-25--d9e86";
export var pv30 = "ourTeam-module--pv-30--85d35";
export var pv35 = "ourTeam-module--pv-35--1a156";
export var pv40 = "ourTeam-module--pv-40--28a1b";
export var pv45 = "ourTeam-module--pv-45--bbcfd";
export var pv48 = "ourTeam-module--pv-48--824f7";
export var pv5 = "ourTeam-module--pv-5--fe432";
export var pv50 = "ourTeam-module--pv-50--afa01";
export var pv55 = "ourTeam-module--pv-55--4bec9";
export var pv60 = "ourTeam-module--pv-60--57366";
export var pv70 = "ourTeam-module--pv-70--90f9c";
export var pv80 = "ourTeam-module--pv-80--6d051";
export var pv85 = "ourTeam-module--pv-85--8ae39";
export var pv90 = "ourTeam-module--pv-90--e7119";
export var pv95 = "ourTeam-module--pv-95--7668a";
export var subtitle = "ourTeam-module--subtitle--216e6";
export var teamWrapper = "ourTeam-module--teamWrapper--4417a";
export var title = "ourTeam-module--title--9a181";
export var titlePart = "ourTeam-module--titlePart--1e895";