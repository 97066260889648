import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { getImgProps } from "helpers/getImgProps";
import * as styles from "./serviceCardAnimation.module.scss";

const ServiceCardAnimation = ({
  hoverImage,
  hoverText,
  text,
  title,
  hoverBackgroundColor,
}) => {
  return (
    <div className={`${styles.deck}`}>
      <div className={styles.card}>
        <div className={`${styles.front} pb-60 ph-30 pt-30`}>
          <p className={`${styles.name} mb-30 pr-20`}>{title}</p>
          <p className={styles.description}>{text} </p>
        </div>
        <Link to="/">
          <div
            className={`${styles.back} pb-60 ph-30 pt-30`}
            style={{ backgroundColor: hoverBackgroundColor }}
          >
            <div className={`${styles.cardImage} mb-25`}>
              <GatsbyImage {...getImgProps(hoverImage)} />
            </div>

            <p className={`${styles.hoverText}`}>{hoverText}</p>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ServiceCardAnimation;
