export const getHomePageProps = ({
  strapiHomepage: {
    seo,
    buildingBold,
    services,
    runningLine,
    caseStudies,
    trustedBy,
    ourTeam,
    reviews,
    LetsTalk,
  },
}) => {
  return {
    seo,
    buildingBold,
    services,
    runningLine,
    caseStudies,
    trustedBy,
    ourTeam,
    reviews,
    letsTalk: LetsTalk,
  };
};
