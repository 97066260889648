import React from "react";
import { graphql } from "gatsby";
import Home from "layouts/home";
import { getHomePageProps } from "helpers/getHomePageProps";
import Layout from "components/layout";

function App({ data }) {
  return (
    <Layout>
      <Home {...getHomePageProps(data)} />
    </Layout>
  );
}

export const query = graphql`
  query HomePageData {
    strapiHomepage {
      seo {
        metaTitle
        metaDescription
        shareImage {
          alternativeText
          localFile {
            url
          }
        }
      }
      buildingBold {
        description
        building
        bold
        ideas
        bluredLogo {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      services {
        serviceCards {
          text
          title
          order
          hoverText
          hoverBackgroundColor
          hoverImage {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        subtitle
        title
      }
      runningLine {
        serviceName {
          order
          color
          title
        }
      }
      caseStudies {
        subtitle
        title
        projects {
          projectTitle
          shortDescription {
            data {
              shortDescription
            }
          }
          image {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
      trustedBy {
        title
        bgTrustedBy {
          localFile {
            url
          }
        }
        imageList {
          order
          image {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
      ourTeam {
        title
        subtitle
        imageList {
          order
          image {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
      reviews {
        arrowLeft {
          alternativeText
          localFile {
            url
          }
        }
        arrowRight {
          alternativeText
          localFile {
            url
          }
        }
        reviews {
          name
          order
          text
          avatar {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          quotes {
            alternativeText
            localFile {
              url
            }
          }
          position
        }
      }
      LetsTalk {
        title
        subtitle
        btn
        bgDark
        address
        btnArrow {
          alternativeText
          localFile {
            url
          }
        }
        preezmaLogo {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;

export default App;
