// extracted by mini-css-extract-plugin
export var arrows = "reviewList-module--arrows--22884";
export var avatar = "reviewList-module--avatar--a9a35";
export var blinkCaret = "reviewList-module--blink-caret--3c46d";
export var buttonLeft = "reviewList-module--buttonLeft--3bcad";
export var buttonRight = "reviewList-module--buttonRight--e637b";
export var emptyBox = "reviewList-module--emptyBox--33927";
export var imgContainer = "reviewList-module--imgContainer--73806";
export var ma0 = "reviewList-module--ma-0--9be0a";
export var ma10 = "reviewList-module--ma-10--5de70";
export var ma100 = "reviewList-module--ma-100--cc90b";
export var ma105 = "reviewList-module--ma-105--0992e";
export var ma110 = "reviewList-module--ma-110--b9b44";
export var ma115 = "reviewList-module--ma-115--94e95";
export var ma12 = "reviewList-module--ma-12--5dd0f";
export var ma120 = "reviewList-module--ma-120--48d13";
export var ma130 = "reviewList-module--ma-130--6f892";
export var ma140 = "reviewList-module--ma-140--9eda3";
export var ma15 = "reviewList-module--ma-15--e7d97";
export var ma150 = "reviewList-module--ma-150--081f3";
export var ma16 = "reviewList-module--ma-16--b71d2";
export var ma18 = "reviewList-module--ma-18--3d11b";
export var ma185 = "reviewList-module--ma-185--61c1a";
export var ma20 = "reviewList-module--ma-20--bf1e7";
export var ma25 = "reviewList-module--ma-25--e1b20";
export var ma30 = "reviewList-module--ma-30--df9b7";
export var ma35 = "reviewList-module--ma-35--05b0c";
export var ma40 = "reviewList-module--ma-40--07ebe";
export var ma45 = "reviewList-module--ma-45--a23a8";
export var ma48 = "reviewList-module--ma-48--1eb96";
export var ma5 = "reviewList-module--ma-5--7898a";
export var ma50 = "reviewList-module--ma-50--41dd0";
export var ma55 = "reviewList-module--ma-55--38ba2";
export var ma60 = "reviewList-module--ma-60--f6172";
export var ma70 = "reviewList-module--ma-70--03510";
export var ma80 = "reviewList-module--ma-80--48b63";
export var ma85 = "reviewList-module--ma-85--7a1ae";
export var ma90 = "reviewList-module--ma-90--513ff";
export var ma95 = "reviewList-module--ma-95--da57a";
export var mb0 = "reviewList-module--mb-0--86e3f";
export var mb10 = "reviewList-module--mb-10--22a3c";
export var mb100 = "reviewList-module--mb-100--0d103";
export var mb105 = "reviewList-module--mb-105--ffe3e";
export var mb110 = "reviewList-module--mb-110--635da";
export var mb115 = "reviewList-module--mb-115--ae072";
export var mb12 = "reviewList-module--mb-12--40ecc";
export var mb120 = "reviewList-module--mb-120--56781";
export var mb130 = "reviewList-module--mb-130--64f4e";
export var mb140 = "reviewList-module--mb-140--43e0f";
export var mb15 = "reviewList-module--mb-15--02c74";
export var mb150 = "reviewList-module--mb-150--29797";
export var mb16 = "reviewList-module--mb-16--7c003";
export var mb18 = "reviewList-module--mb-18--c42cd";
export var mb185 = "reviewList-module--mb-185--360da";
export var mb20 = "reviewList-module--mb-20--16e11";
export var mb25 = "reviewList-module--mb-25--2c4c7";
export var mb30 = "reviewList-module--mb-30--f7ed7";
export var mb35 = "reviewList-module--mb-35--995f6";
export var mb40 = "reviewList-module--mb-40--97d45";
export var mb45 = "reviewList-module--mb-45--c2ffc";
export var mb48 = "reviewList-module--mb-48--93f59";
export var mb5 = "reviewList-module--mb-5--40537";
export var mb50 = "reviewList-module--mb-50--fc092";
export var mb55 = "reviewList-module--mb-55--dec7f";
export var mb60 = "reviewList-module--mb-60--dd961";
export var mb70 = "reviewList-module--mb-70--7cf22";
export var mb80 = "reviewList-module--mb-80--2c937";
export var mb85 = "reviewList-module--mb-85--15113";
export var mb90 = "reviewList-module--mb-90--6256b";
export var mb95 = "reviewList-module--mb-95--fa2d9";
export var mh0 = "reviewList-module--mh-0--60011";
export var mh10 = "reviewList-module--mh-10--86597";
export var mh100 = "reviewList-module--mh-100--c9ba3";
export var mh105 = "reviewList-module--mh-105--ab465";
export var mh110 = "reviewList-module--mh-110--1ce75";
export var mh115 = "reviewList-module--mh-115--7baac";
export var mh12 = "reviewList-module--mh-12--20148";
export var mh120 = "reviewList-module--mh-120--21d0e";
export var mh130 = "reviewList-module--mh-130--34c81";
export var mh140 = "reviewList-module--mh-140--4a0e3";
export var mh15 = "reviewList-module--mh-15--37680";
export var mh150 = "reviewList-module--mh-150--76504";
export var mh16 = "reviewList-module--mh-16--dabda";
export var mh18 = "reviewList-module--mh-18--c765b";
export var mh185 = "reviewList-module--mh-185--e101e";
export var mh20 = "reviewList-module--mh-20--42be6";
export var mh25 = "reviewList-module--mh-25--44f53";
export var mh30 = "reviewList-module--mh-30--74deb";
export var mh35 = "reviewList-module--mh-35--c394e";
export var mh40 = "reviewList-module--mh-40--cf733";
export var mh45 = "reviewList-module--mh-45--e8777";
export var mh48 = "reviewList-module--mh-48--137d3";
export var mh5 = "reviewList-module--mh-5--00181";
export var mh50 = "reviewList-module--mh-50--a3b57";
export var mh55 = "reviewList-module--mh-55--c24fe";
export var mh60 = "reviewList-module--mh-60--76dfc";
export var mh70 = "reviewList-module--mh-70--d158f";
export var mh80 = "reviewList-module--mh-80--79683";
export var mh85 = "reviewList-module--mh-85--8c235";
export var mh90 = "reviewList-module--mh-90--62606";
export var mh95 = "reviewList-module--mh-95--1eea9";
export var ml0 = "reviewList-module--ml-0--a502a";
export var ml10 = "reviewList-module--ml-10--1f245";
export var ml100 = "reviewList-module--ml-100--fc001";
export var ml105 = "reviewList-module--ml-105--4749d";
export var ml110 = "reviewList-module--ml-110--116c3";
export var ml115 = "reviewList-module--ml-115--85199";
export var ml12 = "reviewList-module--ml-12--a35a4";
export var ml120 = "reviewList-module--ml-120--2a799";
export var ml130 = "reviewList-module--ml-130--bb570";
export var ml140 = "reviewList-module--ml-140--cfe6c";
export var ml15 = "reviewList-module--ml-15--9e5d6";
export var ml150 = "reviewList-module--ml-150--164bd";
export var ml16 = "reviewList-module--ml-16--69d94";
export var ml18 = "reviewList-module--ml-18--a1eea";
export var ml185 = "reviewList-module--ml-185--833ad";
export var ml20 = "reviewList-module--ml-20--b6e4a";
export var ml25 = "reviewList-module--ml-25--94c5f";
export var ml30 = "reviewList-module--ml-30--3ecec";
export var ml35 = "reviewList-module--ml-35--d6be3";
export var ml40 = "reviewList-module--ml-40--a8ca6";
export var ml45 = "reviewList-module--ml-45--3b8d1";
export var ml48 = "reviewList-module--ml-48--775f8";
export var ml5 = "reviewList-module--ml-5--e5a95";
export var ml50 = "reviewList-module--ml-50--ee372";
export var ml55 = "reviewList-module--ml-55--b83b5";
export var ml60 = "reviewList-module--ml-60--b8f83";
export var ml70 = "reviewList-module--ml-70--630e9";
export var ml80 = "reviewList-module--ml-80--5f94c";
export var ml85 = "reviewList-module--ml-85--4315a";
export var ml90 = "reviewList-module--ml-90--c5ffc";
export var ml95 = "reviewList-module--ml-95--fa836";
export var mr0 = "reviewList-module--mr-0--640a3";
export var mr10 = "reviewList-module--mr-10--9751a";
export var mr100 = "reviewList-module--mr-100--8a988";
export var mr105 = "reviewList-module--mr-105--fa59f";
export var mr110 = "reviewList-module--mr-110--1aad5";
export var mr115 = "reviewList-module--mr-115--da261";
export var mr12 = "reviewList-module--mr-12--6cf56";
export var mr120 = "reviewList-module--mr-120--625ee";
export var mr130 = "reviewList-module--mr-130--abff9";
export var mr140 = "reviewList-module--mr-140--855f8";
export var mr15 = "reviewList-module--mr-15--1f8f1";
export var mr150 = "reviewList-module--mr-150--5de86";
export var mr16 = "reviewList-module--mr-16--75f30";
export var mr18 = "reviewList-module--mr-18--6cfd4";
export var mr185 = "reviewList-module--mr-185--650a0";
export var mr20 = "reviewList-module--mr-20--1733d";
export var mr25 = "reviewList-module--mr-25--a19d5";
export var mr30 = "reviewList-module--mr-30--6ee76";
export var mr35 = "reviewList-module--mr-35--66cc3";
export var mr40 = "reviewList-module--mr-40--a5718";
export var mr45 = "reviewList-module--mr-45--767ec";
export var mr48 = "reviewList-module--mr-48--8d7f8";
export var mr5 = "reviewList-module--mr-5--370d8";
export var mr50 = "reviewList-module--mr-50--45959";
export var mr55 = "reviewList-module--mr-55--c96bf";
export var mr60 = "reviewList-module--mr-60--d7264";
export var mr70 = "reviewList-module--mr-70--e9976";
export var mr80 = "reviewList-module--mr-80--8c9cf";
export var mr85 = "reviewList-module--mr-85--fccba";
export var mr90 = "reviewList-module--mr-90--872c2";
export var mr95 = "reviewList-module--mr-95--6d66a";
export var mt0 = "reviewList-module--mt-0--22bcd";
export var mt10 = "reviewList-module--mt-10--1387c";
export var mt100 = "reviewList-module--mt-100--ff523";
export var mt105 = "reviewList-module--mt-105--40d47";
export var mt110 = "reviewList-module--mt-110--0971a";
export var mt115 = "reviewList-module--mt-115--0a7b9";
export var mt12 = "reviewList-module--mt-12--dfa27";
export var mt120 = "reviewList-module--mt-120--9d4c7";
export var mt130 = "reviewList-module--mt-130--7e3a0";
export var mt140 = "reviewList-module--mt-140--34849";
export var mt15 = "reviewList-module--mt-15--3495c";
export var mt150 = "reviewList-module--mt-150--8b512";
export var mt16 = "reviewList-module--mt-16--d5be6";
export var mt18 = "reviewList-module--mt-18--79bdf";
export var mt185 = "reviewList-module--mt-185--3a111";
export var mt20 = "reviewList-module--mt-20--891df";
export var mt25 = "reviewList-module--mt-25--e2790";
export var mt30 = "reviewList-module--mt-30--aa6d5";
export var mt35 = "reviewList-module--mt-35--0ef4d";
export var mt40 = "reviewList-module--mt-40--d5870";
export var mt45 = "reviewList-module--mt-45--09498";
export var mt48 = "reviewList-module--mt-48--279e3";
export var mt5 = "reviewList-module--mt-5--bf285";
export var mt50 = "reviewList-module--mt-50--375f2";
export var mt55 = "reviewList-module--mt-55--09493";
export var mt60 = "reviewList-module--mt-60--80e1f";
export var mt70 = "reviewList-module--mt-70--84d42";
export var mt80 = "reviewList-module--mt-80--eccb1";
export var mt85 = "reviewList-module--mt-85--80e31";
export var mt90 = "reviewList-module--mt-90--0bc8d";
export var mt95 = "reviewList-module--mt-95--deffd";
export var mv0 = "reviewList-module--mv-0--ceba3";
export var mv10 = "reviewList-module--mv-10--b6538";
export var mv100 = "reviewList-module--mv-100--c4422";
export var mv105 = "reviewList-module--mv-105--c07da";
export var mv110 = "reviewList-module--mv-110--5c4eb";
export var mv115 = "reviewList-module--mv-115--26218";
export var mv12 = "reviewList-module--mv-12--afdae";
export var mv120 = "reviewList-module--mv-120--c5f01";
export var mv130 = "reviewList-module--mv-130--56bc3";
export var mv140 = "reviewList-module--mv-140--e8f89";
export var mv15 = "reviewList-module--mv-15--3fe69";
export var mv150 = "reviewList-module--mv-150--a1997";
export var mv16 = "reviewList-module--mv-16--c6206";
export var mv18 = "reviewList-module--mv-18--ee8e0";
export var mv185 = "reviewList-module--mv-185--72bde";
export var mv20 = "reviewList-module--mv-20--bfe10";
export var mv25 = "reviewList-module--mv-25--67dcd";
export var mv30 = "reviewList-module--mv-30--4ac5b";
export var mv35 = "reviewList-module--mv-35--2a079";
export var mv40 = "reviewList-module--mv-40--7b91b";
export var mv45 = "reviewList-module--mv-45--75351";
export var mv48 = "reviewList-module--mv-48--5c5d3";
export var mv5 = "reviewList-module--mv-5--afeeb";
export var mv50 = "reviewList-module--mv-50--568b6";
export var mv55 = "reviewList-module--mv-55--a90d7";
export var mv60 = "reviewList-module--mv-60--d7b64";
export var mv70 = "reviewList-module--mv-70--7d6e7";
export var mv80 = "reviewList-module--mv-80--7097f";
export var mv85 = "reviewList-module--mv-85--4d1b9";
export var mv90 = "reviewList-module--mv-90--575ad";
export var mv95 = "reviewList-module--mv-95--dd51c";
export var name = "reviewList-module--name--af32f";
export var pa0 = "reviewList-module--pa-0--1b4ab";
export var pa10 = "reviewList-module--pa-10--95383";
export var pa100 = "reviewList-module--pa-100--c0ee0";
export var pa105 = "reviewList-module--pa-105--cc853";
export var pa110 = "reviewList-module--pa-110--f9e78";
export var pa115 = "reviewList-module--pa-115--84eda";
export var pa12 = "reviewList-module--pa-12--c4161";
export var pa120 = "reviewList-module--pa-120--56aa7";
export var pa130 = "reviewList-module--pa-130--c90d3";
export var pa140 = "reviewList-module--pa-140--9682d";
export var pa15 = "reviewList-module--pa-15--27531";
export var pa150 = "reviewList-module--pa-150--e6de9";
export var pa16 = "reviewList-module--pa-16--c094c";
export var pa18 = "reviewList-module--pa-18--c4764";
export var pa185 = "reviewList-module--pa-185--08a64";
export var pa20 = "reviewList-module--pa-20--b8084";
export var pa25 = "reviewList-module--pa-25--8b67d";
export var pa30 = "reviewList-module--pa-30--bcf5c";
export var pa35 = "reviewList-module--pa-35--68774";
export var pa40 = "reviewList-module--pa-40--3f004";
export var pa45 = "reviewList-module--pa-45--af371";
export var pa48 = "reviewList-module--pa-48--eaf57";
export var pa5 = "reviewList-module--pa-5--486ef";
export var pa50 = "reviewList-module--pa-50--cfc70";
export var pa55 = "reviewList-module--pa-55--e9862";
export var pa60 = "reviewList-module--pa-60--f244e";
export var pa70 = "reviewList-module--pa-70--89e4a";
export var pa80 = "reviewList-module--pa-80--5ae3c";
export var pa85 = "reviewList-module--pa-85--8fe67";
export var pa90 = "reviewList-module--pa-90--00cf4";
export var pa95 = "reviewList-module--pa-95--a149b";
export var pb0 = "reviewList-module--pb-0--66e67";
export var pb10 = "reviewList-module--pb-10--fb84e";
export var pb100 = "reviewList-module--pb-100--6ccfc";
export var pb105 = "reviewList-module--pb-105--3114b";
export var pb110 = "reviewList-module--pb-110--52835";
export var pb115 = "reviewList-module--pb-115--947c1";
export var pb12 = "reviewList-module--pb-12--d6d7f";
export var pb120 = "reviewList-module--pb-120--eecb5";
export var pb130 = "reviewList-module--pb-130--c0b50";
export var pb140 = "reviewList-module--pb-140--06441";
export var pb15 = "reviewList-module--pb-15--92fc8";
export var pb150 = "reviewList-module--pb-150--36b1d";
export var pb16 = "reviewList-module--pb-16--c1245";
export var pb18 = "reviewList-module--pb-18--e24cd";
export var pb185 = "reviewList-module--pb-185--857e3";
export var pb20 = "reviewList-module--pb-20--18ab4";
export var pb25 = "reviewList-module--pb-25--c2796";
export var pb30 = "reviewList-module--pb-30--b5f44";
export var pb35 = "reviewList-module--pb-35--85e01";
export var pb40 = "reviewList-module--pb-40--2a94f";
export var pb45 = "reviewList-module--pb-45--e4335";
export var pb48 = "reviewList-module--pb-48--546e0";
export var pb5 = "reviewList-module--pb-5--c53aa";
export var pb50 = "reviewList-module--pb-50--6b950";
export var pb55 = "reviewList-module--pb-55--92c62";
export var pb60 = "reviewList-module--pb-60--50899";
export var pb70 = "reviewList-module--pb-70--d56c2";
export var pb80 = "reviewList-module--pb-80--8fe7f";
export var pb85 = "reviewList-module--pb-85--e7e66";
export var pb90 = "reviewList-module--pb-90--1a107";
export var pb95 = "reviewList-module--pb-95--b4c1f";
export var ph0 = "reviewList-module--ph-0--1c984";
export var ph10 = "reviewList-module--ph-10--b43b2";
export var ph100 = "reviewList-module--ph-100--24caf";
export var ph105 = "reviewList-module--ph-105--02082";
export var ph110 = "reviewList-module--ph-110--f9c61";
export var ph115 = "reviewList-module--ph-115--72311";
export var ph12 = "reviewList-module--ph-12--2bd23";
export var ph120 = "reviewList-module--ph-120--e3646";
export var ph130 = "reviewList-module--ph-130--2c62c";
export var ph140 = "reviewList-module--ph-140--9a41c";
export var ph15 = "reviewList-module--ph-15--42b1d";
export var ph150 = "reviewList-module--ph-150--30539";
export var ph16 = "reviewList-module--ph-16--1b412";
export var ph18 = "reviewList-module--ph-18--c5288";
export var ph185 = "reviewList-module--ph-185--74834";
export var ph20 = "reviewList-module--ph-20--9258a";
export var ph25 = "reviewList-module--ph-25--3f284";
export var ph30 = "reviewList-module--ph-30--a8883";
export var ph35 = "reviewList-module--ph-35--ee8c8";
export var ph40 = "reviewList-module--ph-40--5fff7";
export var ph45 = "reviewList-module--ph-45--fb97d";
export var ph48 = "reviewList-module--ph-48--b0ca3";
export var ph5 = "reviewList-module--ph-5--cdc6d";
export var ph50 = "reviewList-module--ph-50--fdcce";
export var ph55 = "reviewList-module--ph-55--60688";
export var ph60 = "reviewList-module--ph-60--a50bd";
export var ph70 = "reviewList-module--ph-70--7d39a";
export var ph80 = "reviewList-module--ph-80--35fb5";
export var ph85 = "reviewList-module--ph-85--4e28a";
export var ph90 = "reviewList-module--ph-90--ced07";
export var ph95 = "reviewList-module--ph-95--e3223";
export var pl0 = "reviewList-module--pl-0--c64e3";
export var pl10 = "reviewList-module--pl-10--c885f";
export var pl100 = "reviewList-module--pl-100--374ac";
export var pl105 = "reviewList-module--pl-105--5a9fd";
export var pl110 = "reviewList-module--pl-110--a0487";
export var pl115 = "reviewList-module--pl-115--f2b7d";
export var pl12 = "reviewList-module--pl-12--761f7";
export var pl120 = "reviewList-module--pl-120--d7cc4";
export var pl130 = "reviewList-module--pl-130--81e55";
export var pl140 = "reviewList-module--pl-140--010ea";
export var pl15 = "reviewList-module--pl-15--36799";
export var pl150 = "reviewList-module--pl-150--bddfe";
export var pl16 = "reviewList-module--pl-16--cb990";
export var pl18 = "reviewList-module--pl-18--71e93";
export var pl185 = "reviewList-module--pl-185--4e21a";
export var pl20 = "reviewList-module--pl-20--f6b6e";
export var pl25 = "reviewList-module--pl-25--6ba07";
export var pl30 = "reviewList-module--pl-30--8b08c";
export var pl35 = "reviewList-module--pl-35--3c9d1";
export var pl40 = "reviewList-module--pl-40--b1cca";
export var pl45 = "reviewList-module--pl-45--540a7";
export var pl48 = "reviewList-module--pl-48--b7eac";
export var pl5 = "reviewList-module--pl-5--50525";
export var pl50 = "reviewList-module--pl-50--6dcee";
export var pl55 = "reviewList-module--pl-55--8a849";
export var pl60 = "reviewList-module--pl-60--3d5c9";
export var pl70 = "reviewList-module--pl-70--f5eb4";
export var pl80 = "reviewList-module--pl-80--14a6b";
export var pl85 = "reviewList-module--pl-85--32b40";
export var pl90 = "reviewList-module--pl-90--90815";
export var pl95 = "reviewList-module--pl-95--58750";
export var position = "reviewList-module--position--76f49";
export var pr0 = "reviewList-module--pr-0--f81ba";
export var pr10 = "reviewList-module--pr-10--55358";
export var pr100 = "reviewList-module--pr-100--682cd";
export var pr105 = "reviewList-module--pr-105--f008a";
export var pr110 = "reviewList-module--pr-110--33316";
export var pr115 = "reviewList-module--pr-115--7cc88";
export var pr12 = "reviewList-module--pr-12--5c372";
export var pr120 = "reviewList-module--pr-120--018b0";
export var pr130 = "reviewList-module--pr-130--7afb4";
export var pr140 = "reviewList-module--pr-140--ae0c5";
export var pr15 = "reviewList-module--pr-15--94b0e";
export var pr150 = "reviewList-module--pr-150--c3227";
export var pr16 = "reviewList-module--pr-16--c8a1d";
export var pr18 = "reviewList-module--pr-18--455d1";
export var pr185 = "reviewList-module--pr-185--3f8e5";
export var pr20 = "reviewList-module--pr-20--bf885";
export var pr25 = "reviewList-module--pr-25--b9a84";
export var pr30 = "reviewList-module--pr-30--c88d5";
export var pr35 = "reviewList-module--pr-35--db8a2";
export var pr40 = "reviewList-module--pr-40--1c06c";
export var pr45 = "reviewList-module--pr-45--cf6b4";
export var pr48 = "reviewList-module--pr-48--84513";
export var pr5 = "reviewList-module--pr-5--44c06";
export var pr50 = "reviewList-module--pr-50--4188d";
export var pr55 = "reviewList-module--pr-55--4f39d";
export var pr60 = "reviewList-module--pr-60--6caac";
export var pr70 = "reviewList-module--pr-70--48b57";
export var pr80 = "reviewList-module--pr-80--c2038";
export var pr85 = "reviewList-module--pr-85--71d01";
export var pr90 = "reviewList-module--pr-90--f9509";
export var pr95 = "reviewList-module--pr-95--e8bc8";
export var pt0 = "reviewList-module--pt-0--1b9b6";
export var pt10 = "reviewList-module--pt-10--a9c6b";
export var pt100 = "reviewList-module--pt-100--25d6b";
export var pt105 = "reviewList-module--pt-105--28016";
export var pt110 = "reviewList-module--pt-110--2521d";
export var pt115 = "reviewList-module--pt-115--c82a2";
export var pt12 = "reviewList-module--pt-12--e56b5";
export var pt120 = "reviewList-module--pt-120--02cda";
export var pt130 = "reviewList-module--pt-130--3bc04";
export var pt140 = "reviewList-module--pt-140--362e9";
export var pt15 = "reviewList-module--pt-15--a9b90";
export var pt150 = "reviewList-module--pt-150--a3797";
export var pt16 = "reviewList-module--pt-16--2f5d3";
export var pt18 = "reviewList-module--pt-18--c79a6";
export var pt185 = "reviewList-module--pt-185--78829";
export var pt20 = "reviewList-module--pt-20--a1285";
export var pt25 = "reviewList-module--pt-25--2dda4";
export var pt30 = "reviewList-module--pt-30--38536";
export var pt35 = "reviewList-module--pt-35--04e7f";
export var pt40 = "reviewList-module--pt-40--fd7f4";
export var pt45 = "reviewList-module--pt-45--bda55";
export var pt48 = "reviewList-module--pt-48--bc7ce";
export var pt5 = "reviewList-module--pt-5--eb7e3";
export var pt50 = "reviewList-module--pt-50--935c8";
export var pt55 = "reviewList-module--pt-55--09e95";
export var pt60 = "reviewList-module--pt-60--a9221";
export var pt70 = "reviewList-module--pt-70--60d55";
export var pt80 = "reviewList-module--pt-80--487da";
export var pt85 = "reviewList-module--pt-85--575a0";
export var pt90 = "reviewList-module--pt-90--5895d";
export var pt95 = "reviewList-module--pt-95--4aede";
export var pv0 = "reviewList-module--pv-0--e909e";
export var pv10 = "reviewList-module--pv-10--8fb3e";
export var pv100 = "reviewList-module--pv-100--7edd4";
export var pv105 = "reviewList-module--pv-105--8da79";
export var pv110 = "reviewList-module--pv-110--7c1b6";
export var pv115 = "reviewList-module--pv-115--6c781";
export var pv12 = "reviewList-module--pv-12--83d29";
export var pv120 = "reviewList-module--pv-120--4c7bb";
export var pv130 = "reviewList-module--pv-130--c0980";
export var pv140 = "reviewList-module--pv-140--2572a";
export var pv15 = "reviewList-module--pv-15--90c69";
export var pv150 = "reviewList-module--pv-150--e5f9d";
export var pv16 = "reviewList-module--pv-16--62ed7";
export var pv18 = "reviewList-module--pv-18--fa432";
export var pv185 = "reviewList-module--pv-185--5466c";
export var pv20 = "reviewList-module--pv-20--baaa6";
export var pv25 = "reviewList-module--pv-25--be72c";
export var pv30 = "reviewList-module--pv-30--a8274";
export var pv35 = "reviewList-module--pv-35--dec17";
export var pv40 = "reviewList-module--pv-40--9edbc";
export var pv45 = "reviewList-module--pv-45--45f1a";
export var pv48 = "reviewList-module--pv-48--e7d9d";
export var pv5 = "reviewList-module--pv-5--40c28";
export var pv50 = "reviewList-module--pv-50--ca691";
export var pv55 = "reviewList-module--pv-55--db2e2";
export var pv60 = "reviewList-module--pv-60--a3823";
export var pv70 = "reviewList-module--pv-70--4e89d";
export var pv80 = "reviewList-module--pv-80--d3647";
export var pv85 = "reviewList-module--pv-85--e5ddb";
export var pv90 = "reviewList-module--pv-90--d0a52";
export var pv95 = "reviewList-module--pv-95--a099c";
export var slideDown = "reviewList-module--slideDown--408c3";
export var text = "reviewList-module--text--89675";
export var textContainer = "reviewList-module--textContainer--3b4ab";
export var title = "reviewList-module--title--73838";
export var typing = "reviewList-module--typing--a6d1f";