import React, { useState, useEffect } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { getImgProps } from "helpers/getImgProps";
import * as styles from "./buildingBold.module.scss";

const BuildingBold = ({ building, bold, ideas, description, bluredLogo }) => {
  const [isZoomed, setIsZoomed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      setIsZoomed(scrollTop > 2);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className={styles.wrapper}>
      <div
        className={`${styles.lines} d-flex align-items-center justify-content-center`}
      >
        <div className={styles.lineOne}></div>
        <div className={styles.lineTwo}></div>
        <div className={styles.lineThree}></div>
        <div className={styles.lineFour}></div>
      </div>
      <section
        className={`${styles.buildingBold} d-flex flex-column align-items-center flex-md-row align-items-md-stretch justify-content-md-end`}
      >
        <div className={`${styles.textWrapper} mt-60 mb-120`}>
          <p className={styles.building}>{building}</p>
          <p className={styles.bold}>{bold}</p>
          <p className={styles.ideas}>{ideas}</p>
          <p className={styles.ideasDescription}>{description}</p>
        </div>
        <div className={styles.imgWrapper}>
          <div
            className={`${styles.logoBluredTwo}  ${
              isZoomed ? styles.zoomed : ""
            }`}
          >
            <GatsbyImage {...getImgProps(bluredLogo)} />
          </div>
        </div>
      </section>
    </div>
  );
};

export default BuildingBold;
