// extracted by mini-css-extract-plugin
export var lineOne = "reviews-module--lineOne--6e63e";
export var lineTwo = "reviews-module--lineTwo--bc9b5";
export var ma0 = "reviews-module--ma-0--f263b";
export var ma10 = "reviews-module--ma-10--97234";
export var ma100 = "reviews-module--ma-100--58bbb";
export var ma105 = "reviews-module--ma-105--4cf07";
export var ma110 = "reviews-module--ma-110--ac867";
export var ma115 = "reviews-module--ma-115--fea8c";
export var ma12 = "reviews-module--ma-12--f9427";
export var ma120 = "reviews-module--ma-120--67217";
export var ma130 = "reviews-module--ma-130--bf8c6";
export var ma140 = "reviews-module--ma-140--60800";
export var ma15 = "reviews-module--ma-15--f7d9d";
export var ma150 = "reviews-module--ma-150--640a9";
export var ma16 = "reviews-module--ma-16--59271";
export var ma18 = "reviews-module--ma-18--f12a9";
export var ma185 = "reviews-module--ma-185--5dda7";
export var ma20 = "reviews-module--ma-20--927af";
export var ma25 = "reviews-module--ma-25--cfd61";
export var ma30 = "reviews-module--ma-30--ebb8a";
export var ma35 = "reviews-module--ma-35--3fc71";
export var ma40 = "reviews-module--ma-40--7ee46";
export var ma45 = "reviews-module--ma-45--1376d";
export var ma48 = "reviews-module--ma-48--32498";
export var ma5 = "reviews-module--ma-5--51b4f";
export var ma50 = "reviews-module--ma-50--129ff";
export var ma55 = "reviews-module--ma-55--cb76c";
export var ma60 = "reviews-module--ma-60--974de";
export var ma70 = "reviews-module--ma-70--50a22";
export var ma80 = "reviews-module--ma-80--1d4b6";
export var ma85 = "reviews-module--ma-85--d0aed";
export var ma90 = "reviews-module--ma-90--f7c48";
export var ma95 = "reviews-module--ma-95--ee93c";
export var mb0 = "reviews-module--mb-0--847bb";
export var mb10 = "reviews-module--mb-10--42538";
export var mb100 = "reviews-module--mb-100--7eb6f";
export var mb105 = "reviews-module--mb-105--9e087";
export var mb110 = "reviews-module--mb-110--ba591";
export var mb115 = "reviews-module--mb-115--f4900";
export var mb12 = "reviews-module--mb-12--c9dff";
export var mb120 = "reviews-module--mb-120--29330";
export var mb130 = "reviews-module--mb-130--17e7c";
export var mb140 = "reviews-module--mb-140--3a165";
export var mb15 = "reviews-module--mb-15--3315e";
export var mb150 = "reviews-module--mb-150--1697e";
export var mb16 = "reviews-module--mb-16--d1f72";
export var mb18 = "reviews-module--mb-18--cd2c4";
export var mb185 = "reviews-module--mb-185--3f866";
export var mb20 = "reviews-module--mb-20--6b1cb";
export var mb25 = "reviews-module--mb-25--f652a";
export var mb30 = "reviews-module--mb-30--379e2";
export var mb35 = "reviews-module--mb-35--be40f";
export var mb40 = "reviews-module--mb-40--c98ed";
export var mb45 = "reviews-module--mb-45--89773";
export var mb48 = "reviews-module--mb-48--617d0";
export var mb5 = "reviews-module--mb-5--72f7b";
export var mb50 = "reviews-module--mb-50--d1f3c";
export var mb55 = "reviews-module--mb-55--07043";
export var mb60 = "reviews-module--mb-60--63b59";
export var mb70 = "reviews-module--mb-70--328f8";
export var mb80 = "reviews-module--mb-80--40d03";
export var mb85 = "reviews-module--mb-85--b91c7";
export var mb90 = "reviews-module--mb-90--45b3a";
export var mb95 = "reviews-module--mb-95--e05f2";
export var mh0 = "reviews-module--mh-0--4b2f7";
export var mh10 = "reviews-module--mh-10--99250";
export var mh100 = "reviews-module--mh-100--606ce";
export var mh105 = "reviews-module--mh-105--9355b";
export var mh110 = "reviews-module--mh-110--3bf62";
export var mh115 = "reviews-module--mh-115--f27ce";
export var mh12 = "reviews-module--mh-12--710ea";
export var mh120 = "reviews-module--mh-120--380bf";
export var mh130 = "reviews-module--mh-130--a2cde";
export var mh140 = "reviews-module--mh-140--df4b7";
export var mh15 = "reviews-module--mh-15--85fb4";
export var mh150 = "reviews-module--mh-150--ee552";
export var mh16 = "reviews-module--mh-16--ee5c2";
export var mh18 = "reviews-module--mh-18--6026e";
export var mh185 = "reviews-module--mh-185--4cde2";
export var mh20 = "reviews-module--mh-20--cb137";
export var mh25 = "reviews-module--mh-25--99bbd";
export var mh30 = "reviews-module--mh-30--8f962";
export var mh35 = "reviews-module--mh-35--c866c";
export var mh40 = "reviews-module--mh-40--16f88";
export var mh45 = "reviews-module--mh-45--7d58f";
export var mh48 = "reviews-module--mh-48--bc6d2";
export var mh5 = "reviews-module--mh-5--46ad2";
export var mh50 = "reviews-module--mh-50--d51fc";
export var mh55 = "reviews-module--mh-55--71735";
export var mh60 = "reviews-module--mh-60--a93a1";
export var mh70 = "reviews-module--mh-70--fb0b1";
export var mh80 = "reviews-module--mh-80--d05c1";
export var mh85 = "reviews-module--mh-85--aee44";
export var mh90 = "reviews-module--mh-90--d783f";
export var mh95 = "reviews-module--mh-95--0e3f3";
export var ml0 = "reviews-module--ml-0--777b5";
export var ml10 = "reviews-module--ml-10--95ccc";
export var ml100 = "reviews-module--ml-100--f4747";
export var ml105 = "reviews-module--ml-105--c9367";
export var ml110 = "reviews-module--ml-110--41cce";
export var ml115 = "reviews-module--ml-115--fc6a2";
export var ml12 = "reviews-module--ml-12--a3d4c";
export var ml120 = "reviews-module--ml-120--9181b";
export var ml130 = "reviews-module--ml-130--9e7b4";
export var ml140 = "reviews-module--ml-140--a4153";
export var ml15 = "reviews-module--ml-15--ede1d";
export var ml150 = "reviews-module--ml-150--3d36a";
export var ml16 = "reviews-module--ml-16--02502";
export var ml18 = "reviews-module--ml-18--4e689";
export var ml185 = "reviews-module--ml-185--3341a";
export var ml20 = "reviews-module--ml-20--62eb9";
export var ml25 = "reviews-module--ml-25--54589";
export var ml30 = "reviews-module--ml-30--e9150";
export var ml35 = "reviews-module--ml-35--67b2e";
export var ml40 = "reviews-module--ml-40--a735a";
export var ml45 = "reviews-module--ml-45--cbd70";
export var ml48 = "reviews-module--ml-48--db11e";
export var ml5 = "reviews-module--ml-5--4cd47";
export var ml50 = "reviews-module--ml-50--3fa9b";
export var ml55 = "reviews-module--ml-55--1a984";
export var ml60 = "reviews-module--ml-60--2b405";
export var ml70 = "reviews-module--ml-70--dd9de";
export var ml80 = "reviews-module--ml-80--032df";
export var ml85 = "reviews-module--ml-85--a36e0";
export var ml90 = "reviews-module--ml-90--0006b";
export var ml95 = "reviews-module--ml-95--cd705";
export var mr0 = "reviews-module--mr-0--101ca";
export var mr10 = "reviews-module--mr-10--1cf67";
export var mr100 = "reviews-module--mr-100--38d4e";
export var mr105 = "reviews-module--mr-105--83342";
export var mr110 = "reviews-module--mr-110--cad3c";
export var mr115 = "reviews-module--mr-115--c30c3";
export var mr12 = "reviews-module--mr-12--c9af2";
export var mr120 = "reviews-module--mr-120--0d70e";
export var mr130 = "reviews-module--mr-130--aeef8";
export var mr140 = "reviews-module--mr-140--958a3";
export var mr15 = "reviews-module--mr-15--83fee";
export var mr150 = "reviews-module--mr-150--8c9bc";
export var mr16 = "reviews-module--mr-16--2e711";
export var mr18 = "reviews-module--mr-18--cd8c0";
export var mr185 = "reviews-module--mr-185--eb060";
export var mr20 = "reviews-module--mr-20--f10c2";
export var mr25 = "reviews-module--mr-25--eb0d2";
export var mr30 = "reviews-module--mr-30--d449b";
export var mr35 = "reviews-module--mr-35--5ba9b";
export var mr40 = "reviews-module--mr-40--c048a";
export var mr45 = "reviews-module--mr-45--27f2f";
export var mr48 = "reviews-module--mr-48--ff6b1";
export var mr5 = "reviews-module--mr-5--a33ee";
export var mr50 = "reviews-module--mr-50--85970";
export var mr55 = "reviews-module--mr-55--dc7b2";
export var mr60 = "reviews-module--mr-60--299e4";
export var mr70 = "reviews-module--mr-70--4cb41";
export var mr80 = "reviews-module--mr-80--9a4f6";
export var mr85 = "reviews-module--mr-85--029b9";
export var mr90 = "reviews-module--mr-90--aecc1";
export var mr95 = "reviews-module--mr-95--2218b";
export var mt0 = "reviews-module--mt-0--d386f";
export var mt10 = "reviews-module--mt-10--7c7be";
export var mt100 = "reviews-module--mt-100--87669";
export var mt105 = "reviews-module--mt-105--68d67";
export var mt110 = "reviews-module--mt-110--b0b5d";
export var mt115 = "reviews-module--mt-115--230d0";
export var mt12 = "reviews-module--mt-12--fa820";
export var mt120 = "reviews-module--mt-120--ccf4b";
export var mt130 = "reviews-module--mt-130--f2ad5";
export var mt140 = "reviews-module--mt-140--30d8c";
export var mt15 = "reviews-module--mt-15--17f89";
export var mt150 = "reviews-module--mt-150--bd598";
export var mt16 = "reviews-module--mt-16--f47a0";
export var mt18 = "reviews-module--mt-18--22d86";
export var mt185 = "reviews-module--mt-185--5ffd0";
export var mt20 = "reviews-module--mt-20--abef6";
export var mt25 = "reviews-module--mt-25--70691";
export var mt30 = "reviews-module--mt-30--90549";
export var mt35 = "reviews-module--mt-35--d428b";
export var mt40 = "reviews-module--mt-40--98ce6";
export var mt45 = "reviews-module--mt-45--dfd94";
export var mt48 = "reviews-module--mt-48--39d46";
export var mt5 = "reviews-module--mt-5--7912c";
export var mt50 = "reviews-module--mt-50--0e5f7";
export var mt55 = "reviews-module--mt-55--26d1d";
export var mt60 = "reviews-module--mt-60--2440e";
export var mt70 = "reviews-module--mt-70--2b755";
export var mt80 = "reviews-module--mt-80--1e702";
export var mt85 = "reviews-module--mt-85--907b5";
export var mt90 = "reviews-module--mt-90--47be2";
export var mt95 = "reviews-module--mt-95--af26a";
export var mv0 = "reviews-module--mv-0--af7cb";
export var mv10 = "reviews-module--mv-10--ba884";
export var mv100 = "reviews-module--mv-100--21247";
export var mv105 = "reviews-module--mv-105--27d3d";
export var mv110 = "reviews-module--mv-110--ccdc0";
export var mv115 = "reviews-module--mv-115--cdf92";
export var mv12 = "reviews-module--mv-12--025eb";
export var mv120 = "reviews-module--mv-120--50f98";
export var mv130 = "reviews-module--mv-130--dc4d3";
export var mv140 = "reviews-module--mv-140--7358d";
export var mv15 = "reviews-module--mv-15--4ac89";
export var mv150 = "reviews-module--mv-150--ebe41";
export var mv16 = "reviews-module--mv-16--6ba73";
export var mv18 = "reviews-module--mv-18--3f376";
export var mv185 = "reviews-module--mv-185--f644f";
export var mv20 = "reviews-module--mv-20--c1d49";
export var mv25 = "reviews-module--mv-25--38ad1";
export var mv30 = "reviews-module--mv-30--9ba2b";
export var mv35 = "reviews-module--mv-35--8118d";
export var mv40 = "reviews-module--mv-40--c8b1a";
export var mv45 = "reviews-module--mv-45--de524";
export var mv48 = "reviews-module--mv-48--24827";
export var mv5 = "reviews-module--mv-5--c2c00";
export var mv50 = "reviews-module--mv-50--e64f1";
export var mv55 = "reviews-module--mv-55--249b2";
export var mv60 = "reviews-module--mv-60--f51df";
export var mv70 = "reviews-module--mv-70--74764";
export var mv80 = "reviews-module--mv-80--712cf";
export var mv85 = "reviews-module--mv-85--8ddaa";
export var mv90 = "reviews-module--mv-90--80c4c";
export var mv95 = "reviews-module--mv-95--a7c6e";
export var pa0 = "reviews-module--pa-0--691fe";
export var pa10 = "reviews-module--pa-10--15b0e";
export var pa100 = "reviews-module--pa-100--14304";
export var pa105 = "reviews-module--pa-105--0db22";
export var pa110 = "reviews-module--pa-110--d8de1";
export var pa115 = "reviews-module--pa-115--877c2";
export var pa12 = "reviews-module--pa-12--fb7ef";
export var pa120 = "reviews-module--pa-120--1400d";
export var pa130 = "reviews-module--pa-130--f81a2";
export var pa140 = "reviews-module--pa-140--828f3";
export var pa15 = "reviews-module--pa-15--24c95";
export var pa150 = "reviews-module--pa-150--9638b";
export var pa16 = "reviews-module--pa-16--64832";
export var pa18 = "reviews-module--pa-18--6d732";
export var pa185 = "reviews-module--pa-185--aae1d";
export var pa20 = "reviews-module--pa-20--bd5cb";
export var pa25 = "reviews-module--pa-25--5a1e5";
export var pa30 = "reviews-module--pa-30--97db4";
export var pa35 = "reviews-module--pa-35--92cdd";
export var pa40 = "reviews-module--pa-40--ffe15";
export var pa45 = "reviews-module--pa-45--c2548";
export var pa48 = "reviews-module--pa-48--c0087";
export var pa5 = "reviews-module--pa-5--170fe";
export var pa50 = "reviews-module--pa-50--7330b";
export var pa55 = "reviews-module--pa-55--52168";
export var pa60 = "reviews-module--pa-60--aa97d";
export var pa70 = "reviews-module--pa-70--b9b6d";
export var pa80 = "reviews-module--pa-80--8fdd2";
export var pa85 = "reviews-module--pa-85--287b4";
export var pa90 = "reviews-module--pa-90--d34e3";
export var pa95 = "reviews-module--pa-95--e5a98";
export var pb0 = "reviews-module--pb-0--3f235";
export var pb10 = "reviews-module--pb-10--4e2ca";
export var pb100 = "reviews-module--pb-100--fc6d7";
export var pb105 = "reviews-module--pb-105--c50ca";
export var pb110 = "reviews-module--pb-110--615c5";
export var pb115 = "reviews-module--pb-115--ba5ff";
export var pb12 = "reviews-module--pb-12--9590f";
export var pb120 = "reviews-module--pb-120--23f84";
export var pb130 = "reviews-module--pb-130--49091";
export var pb140 = "reviews-module--pb-140--6c913";
export var pb15 = "reviews-module--pb-15--b9b97";
export var pb150 = "reviews-module--pb-150--692d6";
export var pb16 = "reviews-module--pb-16--28080";
export var pb18 = "reviews-module--pb-18--2de9e";
export var pb185 = "reviews-module--pb-185--e6451";
export var pb20 = "reviews-module--pb-20--ba7e4";
export var pb25 = "reviews-module--pb-25--17f96";
export var pb30 = "reviews-module--pb-30--353d0";
export var pb35 = "reviews-module--pb-35--8492c";
export var pb40 = "reviews-module--pb-40--759a3";
export var pb45 = "reviews-module--pb-45--33d29";
export var pb48 = "reviews-module--pb-48--44f23";
export var pb5 = "reviews-module--pb-5--9235c";
export var pb50 = "reviews-module--pb-50--44b14";
export var pb55 = "reviews-module--pb-55--3b324";
export var pb60 = "reviews-module--pb-60--70e16";
export var pb70 = "reviews-module--pb-70--893a6";
export var pb80 = "reviews-module--pb-80--8c5eb";
export var pb85 = "reviews-module--pb-85--52fcb";
export var pb90 = "reviews-module--pb-90--710d5";
export var pb95 = "reviews-module--pb-95--08848";
export var ph0 = "reviews-module--ph-0--2aa46";
export var ph10 = "reviews-module--ph-10--92255";
export var ph100 = "reviews-module--ph-100--472e4";
export var ph105 = "reviews-module--ph-105--48dcf";
export var ph110 = "reviews-module--ph-110--b222c";
export var ph115 = "reviews-module--ph-115--b37d2";
export var ph12 = "reviews-module--ph-12--1315c";
export var ph120 = "reviews-module--ph-120--e1139";
export var ph130 = "reviews-module--ph-130--42f20";
export var ph140 = "reviews-module--ph-140--3202e";
export var ph15 = "reviews-module--ph-15--5f705";
export var ph150 = "reviews-module--ph-150--f6a1f";
export var ph16 = "reviews-module--ph-16--e287b";
export var ph18 = "reviews-module--ph-18--690b9";
export var ph185 = "reviews-module--ph-185--59bbd";
export var ph20 = "reviews-module--ph-20--d7258";
export var ph25 = "reviews-module--ph-25--db67a";
export var ph30 = "reviews-module--ph-30--ecb96";
export var ph35 = "reviews-module--ph-35--e55f6";
export var ph40 = "reviews-module--ph-40--dc39b";
export var ph45 = "reviews-module--ph-45--4e69a";
export var ph48 = "reviews-module--ph-48--129b7";
export var ph5 = "reviews-module--ph-5--9ed9e";
export var ph50 = "reviews-module--ph-50--b22b8";
export var ph55 = "reviews-module--ph-55--1d513";
export var ph60 = "reviews-module--ph-60--3b1d7";
export var ph70 = "reviews-module--ph-70--3c8d4";
export var ph80 = "reviews-module--ph-80--1be0d";
export var ph85 = "reviews-module--ph-85--15247";
export var ph90 = "reviews-module--ph-90--6ea15";
export var ph95 = "reviews-module--ph-95--a209c";
export var pl0 = "reviews-module--pl-0--6d0b7";
export var pl10 = "reviews-module--pl-10--83429";
export var pl100 = "reviews-module--pl-100--99de9";
export var pl105 = "reviews-module--pl-105--d0930";
export var pl110 = "reviews-module--pl-110--c97fe";
export var pl115 = "reviews-module--pl-115--55660";
export var pl12 = "reviews-module--pl-12--c19b2";
export var pl120 = "reviews-module--pl-120--17ebd";
export var pl130 = "reviews-module--pl-130--d7812";
export var pl140 = "reviews-module--pl-140--e770e";
export var pl15 = "reviews-module--pl-15--3482e";
export var pl150 = "reviews-module--pl-150--12e29";
export var pl16 = "reviews-module--pl-16--766ed";
export var pl18 = "reviews-module--pl-18--29185";
export var pl185 = "reviews-module--pl-185--1190d";
export var pl20 = "reviews-module--pl-20--51d04";
export var pl25 = "reviews-module--pl-25--9309c";
export var pl30 = "reviews-module--pl-30--82c7a";
export var pl35 = "reviews-module--pl-35--7b463";
export var pl40 = "reviews-module--pl-40--8a921";
export var pl45 = "reviews-module--pl-45--2d056";
export var pl48 = "reviews-module--pl-48--b7436";
export var pl5 = "reviews-module--pl-5--b3461";
export var pl50 = "reviews-module--pl-50--c1842";
export var pl55 = "reviews-module--pl-55--3bc07";
export var pl60 = "reviews-module--pl-60--3d013";
export var pl70 = "reviews-module--pl-70--d9895";
export var pl80 = "reviews-module--pl-80--5059a";
export var pl85 = "reviews-module--pl-85--8c854";
export var pl90 = "reviews-module--pl-90--ec88c";
export var pl95 = "reviews-module--pl-95--eab17";
export var pr0 = "reviews-module--pr-0--72320";
export var pr10 = "reviews-module--pr-10--ea015";
export var pr100 = "reviews-module--pr-100--bf3e4";
export var pr105 = "reviews-module--pr-105--d3592";
export var pr110 = "reviews-module--pr-110--a1ad1";
export var pr115 = "reviews-module--pr-115--15444";
export var pr12 = "reviews-module--pr-12--f4519";
export var pr120 = "reviews-module--pr-120--9f4c0";
export var pr130 = "reviews-module--pr-130--9e545";
export var pr140 = "reviews-module--pr-140--19e5e";
export var pr15 = "reviews-module--pr-15--6eb8d";
export var pr150 = "reviews-module--pr-150--f728c";
export var pr16 = "reviews-module--pr-16--7b2f4";
export var pr18 = "reviews-module--pr-18--99a7e";
export var pr185 = "reviews-module--pr-185--e8d96";
export var pr20 = "reviews-module--pr-20--69ece";
export var pr25 = "reviews-module--pr-25--6d0eb";
export var pr30 = "reviews-module--pr-30--16132";
export var pr35 = "reviews-module--pr-35--f4b1c";
export var pr40 = "reviews-module--pr-40--f1dec";
export var pr45 = "reviews-module--pr-45--c927f";
export var pr48 = "reviews-module--pr-48--5f1af";
export var pr5 = "reviews-module--pr-5--f59c3";
export var pr50 = "reviews-module--pr-50--0d699";
export var pr55 = "reviews-module--pr-55--009cc";
export var pr60 = "reviews-module--pr-60--80574";
export var pr70 = "reviews-module--pr-70--101ff";
export var pr80 = "reviews-module--pr-80--6e62a";
export var pr85 = "reviews-module--pr-85--d8a70";
export var pr90 = "reviews-module--pr-90--c34a9";
export var pr95 = "reviews-module--pr-95--0e380";
export var pt0 = "reviews-module--pt-0--4c922";
export var pt10 = "reviews-module--pt-10--ca3ad";
export var pt100 = "reviews-module--pt-100--ab3b8";
export var pt105 = "reviews-module--pt-105--02f5f";
export var pt110 = "reviews-module--pt-110--3d5a0";
export var pt115 = "reviews-module--pt-115--bb901";
export var pt12 = "reviews-module--pt-12--016eb";
export var pt120 = "reviews-module--pt-120--a421f";
export var pt130 = "reviews-module--pt-130--41d6f";
export var pt140 = "reviews-module--pt-140--6c992";
export var pt15 = "reviews-module--pt-15--fe919";
export var pt150 = "reviews-module--pt-150--f111c";
export var pt16 = "reviews-module--pt-16--04ac1";
export var pt18 = "reviews-module--pt-18--8a0b1";
export var pt185 = "reviews-module--pt-185--bfb79";
export var pt20 = "reviews-module--pt-20--d1eb8";
export var pt25 = "reviews-module--pt-25--3d97f";
export var pt30 = "reviews-module--pt-30--8da38";
export var pt35 = "reviews-module--pt-35--8fa07";
export var pt40 = "reviews-module--pt-40--8d41b";
export var pt45 = "reviews-module--pt-45--a27ef";
export var pt48 = "reviews-module--pt-48--a431f";
export var pt5 = "reviews-module--pt-5--14c53";
export var pt50 = "reviews-module--pt-50--46b7e";
export var pt55 = "reviews-module--pt-55--14866";
export var pt60 = "reviews-module--pt-60--77e8b";
export var pt70 = "reviews-module--pt-70--77f94";
export var pt80 = "reviews-module--pt-80--f98bf";
export var pt85 = "reviews-module--pt-85--195d8";
export var pt90 = "reviews-module--pt-90--6367b";
export var pt95 = "reviews-module--pt-95--e9a88";
export var pv0 = "reviews-module--pv-0--01ed8";
export var pv10 = "reviews-module--pv-10--852f9";
export var pv100 = "reviews-module--pv-100--57c0b";
export var pv105 = "reviews-module--pv-105--ea389";
export var pv110 = "reviews-module--pv-110--6d442";
export var pv115 = "reviews-module--pv-115--fccdc";
export var pv12 = "reviews-module--pv-12--cf9f0";
export var pv120 = "reviews-module--pv-120--34e66";
export var pv130 = "reviews-module--pv-130--7dcb0";
export var pv140 = "reviews-module--pv-140--79f54";
export var pv15 = "reviews-module--pv-15--fb663";
export var pv150 = "reviews-module--pv-150--e827b";
export var pv16 = "reviews-module--pv-16--43d90";
export var pv18 = "reviews-module--pv-18--e3ea8";
export var pv185 = "reviews-module--pv-185--17a3e";
export var pv20 = "reviews-module--pv-20--8ebe0";
export var pv25 = "reviews-module--pv-25--329fc";
export var pv30 = "reviews-module--pv-30--894ca";
export var pv35 = "reviews-module--pv-35--60b23";
export var pv40 = "reviews-module--pv-40--cd0b4";
export var pv45 = "reviews-module--pv-45--c4d82";
export var pv48 = "reviews-module--pv-48--ba8f5";
export var pv5 = "reviews-module--pv-5--e4bfe";
export var pv50 = "reviews-module--pv-50--71a85";
export var pv55 = "reviews-module--pv-55--27dee";
export var pv60 = "reviews-module--pv-60--47568";
export var pv70 = "reviews-module--pv-70--63bb6";
export var pv80 = "reviews-module--pv-80--f5b36";
export var pv85 = "reviews-module--pv-85--daa64";
export var pv90 = "reviews-module--pv-90--c372a";
export var pv95 = "reviews-module--pv-95--23525";
export var reviewsWrapper = "reviews-module--reviewsWrapper--c5534";