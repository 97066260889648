import React from "react";
import { sortArrayOfObjects } from "helpers/sortArrayOfObjects";
import * as styles from "./runningLine.module.scss";

const RunningLine = ({ serviceName }) => {
  const data = sortArrayOfObjects(
    serviceName.map(({ title, color }, i) => {
      return (
        <span className={styles.item} key={i} style={{ color }}>
          {title}
        </span>
      );
    })
  );
  return (
    <div className={`${styles.wrapper} pv-60 mb-95`}>
      <div className={styles.move}>
        {data} ・ {data} ・
      </div>
      <div className={styles.move}>
        {data} ・ {data} ・
      </div>
      <div className={styles.move}>
        {data} ・ {data} ・
      </div>
    </div>
  );
};

export default RunningLine;
