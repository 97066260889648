import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { getImgProps } from "helpers/getImgProps";
import * as styles from "./ourTeam.module.scss";

const OurTeam = ({ title, subtitle, imageList }) => {
  const imgList = imageList.map(({ image }, i) => {
    return (
      <div key={i} className={`${styles.imgContainer} `}>
        <GatsbyImage {...getImgProps(image)} />
      </div>
    );
  });

  return (
    <div className={`${styles.teamWrapper} mb-185`}>
      <div className={styles.titlePart}>
        <p className={`${styles.title} mb-20`}>{title}</p>
        <p className={`${styles.subtitle} mb-50`}>{subtitle}</p>
      </div>
      <div>
        <div
          className={`${styles.images} d-flex flex-column flex-md-row align-items-center align-items-md-start flex-md-row justify-content-md-between`}
        >
          {imgList}
        </div>
      </div>
    </div>
  );
};

export default OurTeam;
