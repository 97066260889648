import React from "react";
import ServiceCardAnimation from "components/serviceCardAnimation";
import { sortArrayOfObjects } from "helpers/sortArrayOfObjects";
import * as styles from "./services.module.scss";

const Services = ({ title, subtitle, serviceCards }) => {
  const serviceCardsTopRow = sortArrayOfObjects(
    serviceCards
      .filter((data) => {
        return data.order <= 3;
      })
      .map((item, i) => {
        return <ServiceCardAnimation key={i} {...item} />;
      })
  );
  const serviceCardsBottomRow = sortArrayOfObjects(
    serviceCards
      .filter((data) => {
        return data.order > 3;
      })
      .map((item, i) => {
        return <ServiceCardAnimation key={i} {...item} />;
      })
  );
  return (
    <div className={styles.wrapperService}>
      <div
        className={`${styles.servicesTitlePart} d-flex justify-content-between align-items-center`}
      >
        <p className={`${styles.title} mb-10`}>{title}</p>
      </div>
      <div className={styles.descriptionWrapper}>
        <p className={`${styles.subtitle} mb-120`}>{subtitle}</p>
      </div>
      <div className={styles.serviceListContainer}>
        <div
          className={`${styles.serviceLists} d-flex flex-column flex-md-row justify-content-md-between align-items-md-stretch`}
        >
          {serviceCardsTopRow}
        </div>
      </div>
      <div className={styles.serviceListContainer}>
        <div
          className={`${styles.serviceLists} d-flex flex-column flex-md-row justify-content-md-between align-items-md-stretch`}
        >
          {serviceCardsBottomRow}
        </div>
      </div>
    </div>
  );
};

export default Services;
