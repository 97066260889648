import React from "react";
import arrow from "images/arrow.png";
import * as styles from "./viewALlButtonAnimation.module.scss";

const ViewAllButtonAnimation = ({ color, handleClick }) => {
  return (
    <div className={styles.wrapper}>
      <div className={`${styles.roundedText} ${styles.rotating}`}>
        <svg viewBox="0 0 200 200">
          <path
            id="textPath"
            d="M 85,0 A 85,85 0 0 1 -85,0 A 85,85 0 0 1 85,0"
            transform="translate(100,100)"
            fill="none"
            strokeWidth="0"
          ></path>
          <text textAnchor="start">
            <textPath
              className={styles.coloring}
              href="#textPath"
              startOffset="0%"
            >
              ・ VIEW ALL PROJECTS ・ VIEW ALL PROJECTS
            </textPath>
          </text>
        </svg>
      </div>
      <div className={styles.btn}>
        <span
          onClick={handleClick}
          role="button"
          tabIndex={0}
          className={styles.arrowWrapper}
          style={{ backgroundColor: color }}
        >
          <img src={arrow} alt="arrow" />
        </span>
      </div>
    </div>
  );
};

export default ViewAllButtonAnimation;
