import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { getImgProps } from "helpers/getImgProps";
import SingleProject from "components/singleProject/index.jsx";
import ViewAllButtonAnimation from "components/viewAllButtonAnimation";
import { navigate } from "gatsby";
import * as styles from "./caseStudies.module.scss";

const CaseStudies = ({ title, subtitle, projects }) => {
  const handleClick = () => {
    navigate("/case-studies");
  };
  const projectList = projects.map(
    ({ projectTitle, shortDescription, image, slug }, index) => {
      return (
        <SingleProject
          key={index}
          image={image}
          title={projectTitle}
          text={shortDescription.data.shortDescription}
          index={index}
          homepage={true}
          slug={slug}
        />
      );
    }
  );

  return (
    <>
      <div className={styles.titlePart}>
        <p className={`${styles.title} mb-20`}>{title}</p>
        <p className={`${styles.subtitle} mb-70`}>{subtitle}</p>
      </div>
      <div className={`${styles.projects}`}>
        <div className={styles.topBtnAll}>
          <ViewAllButtonAnimation color="#f2c081" handleClick={handleClick} />
        </div>
        {projectList}
        <div className={styles.bottomBtnAll}>
          <ViewAllButtonAnimation color="#95E2DA" handleClick={handleClick} />
        </div>
      </div>
    </>
  );
};

export default CaseStudies;
