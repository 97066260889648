import React, { useEffect, useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { getImgProps, getStaticImgProps } from "helpers/getImgProps";
import { sortArrayOfObjects } from "helpers/sortArrayOfObjects";
import * as styles from "./reviewList.module.scss";

const ReviewList = ({ arrowRight, reviews, interval = 7000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentIndex((currentIndex) => (currentIndex + 1) % reviews.length);
    }, interval);

    return () => {
      clearInterval(slideInterval);
    };
  }, [reviews.length, interval]);

  const showPrev = () => {
    return currentIndex === 0
      ? setCurrentIndex(reviews.length - 1)
      : setCurrentIndex(currentIndex - 1);
  };
  const showNext = () => {
    return currentIndex === reviews.length - 1
      ? setCurrentIndex(0)
      : setCurrentIndex(currentIndex + 1);
  };
  const reviewList = sortArrayOfObjects(
    reviews.map(({ avatar, name, position, quotes, text }, i) => {
      return (
        <div
          key={i}
          className="d-flex flex-column flex-md-row justify-content--md-start align-items-md-center"
        >
          <div
            className={`${styles.imgContainer} d-flex justify-content-end justify-content-md-start align-items-stretch`}
          >
            <div className={styles.emptyBox}></div>
            <div className={`${styles.avatar} mh-30`}>
              <GatsbyImage {...getImgProps(avatar)} />
            </div>
          </div>
          <div className={`${styles.textContainer} ml-50`}>
            <div className={styles.title}>WHAT PEOPLE SAY ABOUT US</div>
            <p className={`${styles.name} mb-10`}>{name}</p>
            <p className={`${styles.position} mb-40`}>{position}</p>
            <p>
              <img {...getStaticImgProps(quotes)} />
            </p>
            <p className={`${styles.text} mv-20`}>{text}</p>
            <div className={styles.arrows}>
              <button onClick={showPrev} className="mr-35">
                <img
                  {...getStaticImgProps(arrowRight)}
                  className={styles.buttonLeft}
                />
              </button>
              <button onClick={showNext}>
                <img {...getStaticImgProps(arrowRight)} />
              </button>
            </div>
          </div>
        </div>
      );
    })
  );
  return <>{reviewList[currentIndex]}</>;
};

export default ReviewList;
