import React from "react";
import { sortArrayOfObjects } from "helpers/sortArrayOfObjects";
import { GatsbyImage } from "gatsby-plugin-image";
import { getImgProps } from "helpers/getImgProps";
import * as styles from "./trustedBy.module.scss";

const TrustedBy = ({ title, imageList, bgTrustedBy }) => {
  const clientList = sortArrayOfObjects(
    imageList.map(({ image }, i) => {
      return (
        <div key={i} className="ma-10">
          <GatsbyImage {...getImgProps(image)} />
        </div>
      );
    })
  );
  const bgUrl = bgTrustedBy.localFile.url;
  return (
    <div
      className={`${styles.trustedWrapper} pa-25`}
      style={{ backgroundImage: "url(" + bgUrl + ")" }}
    >
      <p className={styles.trustedTitle}>{title}</p>
      <div
        className={`${styles.clientList} mt-40  mb-120 d-flex justify-content-xl-around justify-content-center align-items-center flex-wrap ph-40 pv-50`}
      >
        {clientList}
      </div>
    </div>
  );
};

export default TrustedBy;
