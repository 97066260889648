import React from "react";
import ReviewList from "components/reviewList/index";
import * as styles from "./reviews.module.scss";

const Reviews = (props) => {
  return (
    <div className={`${styles.reviewsWrapper} pv-100 mb-140`}>
      <div className={styles.lineOne}></div>
      <div className={styles.lineTwo}></div>

      <ReviewList {...props} />
    </div>
  );
};

export default Reviews;
