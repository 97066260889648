// extracted by mini-css-extract-plugin
export var bold = "buildingBold-module--bold--60bc6";
export var building = "buildingBold-module--building--2fc7f";
export var buildingBold = "buildingBold-module--buildingBold--218aa";
export var ideas = "buildingBold-module--ideas--0e83e";
export var ideasDescription = "buildingBold-module--ideasDescription--f35f9";
export var imgWrapper = "buildingBold-module--imgWrapper--b00ef";
export var lineFour = "buildingBold-module--lineFour--7f2bf";
export var lineOne = "buildingBold-module--lineOne--80fec";
export var lineThree = "buildingBold-module--lineThree--e85a1";
export var lineTwo = "buildingBold-module--lineTwo--6f0de";
export var lines = "buildingBold-module--lines--3e044";
export var logoBluredTwo = "buildingBold-module--logoBluredTwo--c9c37";
export var ma0 = "buildingBold-module--ma-0--03dda";
export var ma10 = "buildingBold-module--ma-10--49866";
export var ma100 = "buildingBold-module--ma-100--4fd5c";
export var ma105 = "buildingBold-module--ma-105--b98d2";
export var ma110 = "buildingBold-module--ma-110--c452f";
export var ma115 = "buildingBold-module--ma-115--3dffe";
export var ma12 = "buildingBold-module--ma-12--29449";
export var ma120 = "buildingBold-module--ma-120--da700";
export var ma130 = "buildingBold-module--ma-130--e4a05";
export var ma140 = "buildingBold-module--ma-140--85973";
export var ma15 = "buildingBold-module--ma-15--3b438";
export var ma150 = "buildingBold-module--ma-150--e7bd1";
export var ma16 = "buildingBold-module--ma-16--c2cd9";
export var ma18 = "buildingBold-module--ma-18--ae6d7";
export var ma185 = "buildingBold-module--ma-185--d5867";
export var ma20 = "buildingBold-module--ma-20--5be88";
export var ma25 = "buildingBold-module--ma-25--0076a";
export var ma30 = "buildingBold-module--ma-30--bd6fb";
export var ma35 = "buildingBold-module--ma-35--e8f41";
export var ma40 = "buildingBold-module--ma-40--27ecd";
export var ma45 = "buildingBold-module--ma-45--9878b";
export var ma48 = "buildingBold-module--ma-48--4a98e";
export var ma5 = "buildingBold-module--ma-5--5720b";
export var ma50 = "buildingBold-module--ma-50--75ac4";
export var ma55 = "buildingBold-module--ma-55--09ef9";
export var ma60 = "buildingBold-module--ma-60--3d043";
export var ma70 = "buildingBold-module--ma-70--e034b";
export var ma80 = "buildingBold-module--ma-80--d6879";
export var ma85 = "buildingBold-module--ma-85--97f9a";
export var ma90 = "buildingBold-module--ma-90--83892";
export var ma95 = "buildingBold-module--ma-95--5e403";
export var mb0 = "buildingBold-module--mb-0--055e1";
export var mb10 = "buildingBold-module--mb-10--72489";
export var mb100 = "buildingBold-module--mb-100--84e3a";
export var mb105 = "buildingBold-module--mb-105--9e84b";
export var mb110 = "buildingBold-module--mb-110--b869d";
export var mb115 = "buildingBold-module--mb-115--f76b0";
export var mb12 = "buildingBold-module--mb-12--e131f";
export var mb120 = "buildingBold-module--mb-120--3847b";
export var mb130 = "buildingBold-module--mb-130--babf1";
export var mb140 = "buildingBold-module--mb-140--dc4b8";
export var mb15 = "buildingBold-module--mb-15--87fd1";
export var mb150 = "buildingBold-module--mb-150--5b3c0";
export var mb16 = "buildingBold-module--mb-16--0204e";
export var mb18 = "buildingBold-module--mb-18--58fcb";
export var mb185 = "buildingBold-module--mb-185--994ad";
export var mb20 = "buildingBold-module--mb-20--2a233";
export var mb25 = "buildingBold-module--mb-25--5f322";
export var mb30 = "buildingBold-module--mb-30--ad050";
export var mb35 = "buildingBold-module--mb-35--3957d";
export var mb40 = "buildingBold-module--mb-40--dfcb1";
export var mb45 = "buildingBold-module--mb-45--e8d54";
export var mb48 = "buildingBold-module--mb-48--963f9";
export var mb5 = "buildingBold-module--mb-5--02012";
export var mb50 = "buildingBold-module--mb-50--16e8d";
export var mb55 = "buildingBold-module--mb-55--9484d";
export var mb60 = "buildingBold-module--mb-60--21162";
export var mb70 = "buildingBold-module--mb-70--e4903";
export var mb80 = "buildingBold-module--mb-80--515bc";
export var mb85 = "buildingBold-module--mb-85--bd102";
export var mb90 = "buildingBold-module--mb-90--2a79b";
export var mb95 = "buildingBold-module--mb-95--b1e49";
export var mh0 = "buildingBold-module--mh-0--77c72";
export var mh10 = "buildingBold-module--mh-10--2b142";
export var mh100 = "buildingBold-module--mh-100--7aa0f";
export var mh105 = "buildingBold-module--mh-105--1e3d4";
export var mh110 = "buildingBold-module--mh-110--23f00";
export var mh115 = "buildingBold-module--mh-115--f4792";
export var mh12 = "buildingBold-module--mh-12--91c67";
export var mh120 = "buildingBold-module--mh-120--086d9";
export var mh130 = "buildingBold-module--mh-130--0a3ba";
export var mh140 = "buildingBold-module--mh-140--acbc3";
export var mh15 = "buildingBold-module--mh-15--eac64";
export var mh150 = "buildingBold-module--mh-150--0f8d7";
export var mh16 = "buildingBold-module--mh-16--668f9";
export var mh18 = "buildingBold-module--mh-18--ed533";
export var mh185 = "buildingBold-module--mh-185--955e2";
export var mh20 = "buildingBold-module--mh-20--9d912";
export var mh25 = "buildingBold-module--mh-25--5caf6";
export var mh30 = "buildingBold-module--mh-30--5c61d";
export var mh35 = "buildingBold-module--mh-35--09db4";
export var mh40 = "buildingBold-module--mh-40--4cb2b";
export var mh45 = "buildingBold-module--mh-45--0dd8e";
export var mh48 = "buildingBold-module--mh-48--5868c";
export var mh5 = "buildingBold-module--mh-5--ff1b1";
export var mh50 = "buildingBold-module--mh-50--ec7c6";
export var mh55 = "buildingBold-module--mh-55--82b50";
export var mh60 = "buildingBold-module--mh-60--8dc5d";
export var mh70 = "buildingBold-module--mh-70--814e3";
export var mh80 = "buildingBold-module--mh-80--ddb79";
export var mh85 = "buildingBold-module--mh-85--cb503";
export var mh90 = "buildingBold-module--mh-90--dabe2";
export var mh95 = "buildingBold-module--mh-95--51a44";
export var ml0 = "buildingBold-module--ml-0--d807b";
export var ml10 = "buildingBold-module--ml-10--51aea";
export var ml100 = "buildingBold-module--ml-100--2bee0";
export var ml105 = "buildingBold-module--ml-105--fd41c";
export var ml110 = "buildingBold-module--ml-110--fc5ad";
export var ml115 = "buildingBold-module--ml-115--db39a";
export var ml12 = "buildingBold-module--ml-12--e015d";
export var ml120 = "buildingBold-module--ml-120--fb0de";
export var ml130 = "buildingBold-module--ml-130--09b4b";
export var ml140 = "buildingBold-module--ml-140--2a9cc";
export var ml15 = "buildingBold-module--ml-15--6ab38";
export var ml150 = "buildingBold-module--ml-150--12a2e";
export var ml16 = "buildingBold-module--ml-16--65465";
export var ml18 = "buildingBold-module--ml-18--2f51e";
export var ml185 = "buildingBold-module--ml-185--17380";
export var ml20 = "buildingBold-module--ml-20--b42a0";
export var ml25 = "buildingBold-module--ml-25--09c1d";
export var ml30 = "buildingBold-module--ml-30--dd5d7";
export var ml35 = "buildingBold-module--ml-35--678c8";
export var ml40 = "buildingBold-module--ml-40--5163a";
export var ml45 = "buildingBold-module--ml-45--20971";
export var ml48 = "buildingBold-module--ml-48--1c7ee";
export var ml5 = "buildingBold-module--ml-5--1a2e1";
export var ml50 = "buildingBold-module--ml-50--8fba1";
export var ml55 = "buildingBold-module--ml-55--b5749";
export var ml60 = "buildingBold-module--ml-60--9417a";
export var ml70 = "buildingBold-module--ml-70--772d8";
export var ml80 = "buildingBold-module--ml-80--0f0e5";
export var ml85 = "buildingBold-module--ml-85--c95e9";
export var ml90 = "buildingBold-module--ml-90--044ef";
export var ml95 = "buildingBold-module--ml-95--7fcdd";
export var mr0 = "buildingBold-module--mr-0--c3abd";
export var mr10 = "buildingBold-module--mr-10--f6fce";
export var mr100 = "buildingBold-module--mr-100--9a8eb";
export var mr105 = "buildingBold-module--mr-105--ecd2c";
export var mr110 = "buildingBold-module--mr-110--7a32c";
export var mr115 = "buildingBold-module--mr-115--acc40";
export var mr12 = "buildingBold-module--mr-12--5f30b";
export var mr120 = "buildingBold-module--mr-120--2270d";
export var mr130 = "buildingBold-module--mr-130--f7957";
export var mr140 = "buildingBold-module--mr-140--d15ea";
export var mr15 = "buildingBold-module--mr-15--780b0";
export var mr150 = "buildingBold-module--mr-150--b75b3";
export var mr16 = "buildingBold-module--mr-16--5adb2";
export var mr18 = "buildingBold-module--mr-18--0adb8";
export var mr185 = "buildingBold-module--mr-185--98e13";
export var mr20 = "buildingBold-module--mr-20--2eaf6";
export var mr25 = "buildingBold-module--mr-25--d6174";
export var mr30 = "buildingBold-module--mr-30--65f3e";
export var mr35 = "buildingBold-module--mr-35--baeab";
export var mr40 = "buildingBold-module--mr-40--23f8d";
export var mr45 = "buildingBold-module--mr-45--d1be9";
export var mr48 = "buildingBold-module--mr-48--1e4b9";
export var mr5 = "buildingBold-module--mr-5--57cef";
export var mr50 = "buildingBold-module--mr-50--6b82a";
export var mr55 = "buildingBold-module--mr-55--6c027";
export var mr60 = "buildingBold-module--mr-60--79f5b";
export var mr70 = "buildingBold-module--mr-70--0837a";
export var mr80 = "buildingBold-module--mr-80--e853b";
export var mr85 = "buildingBold-module--mr-85--09a5d";
export var mr90 = "buildingBold-module--mr-90--79ab8";
export var mr95 = "buildingBold-module--mr-95--8285b";
export var mt0 = "buildingBold-module--mt-0--7968d";
export var mt10 = "buildingBold-module--mt-10--defe4";
export var mt100 = "buildingBold-module--mt-100--12f1a";
export var mt105 = "buildingBold-module--mt-105--b4c47";
export var mt110 = "buildingBold-module--mt-110--0ad7b";
export var mt115 = "buildingBold-module--mt-115--69aac";
export var mt12 = "buildingBold-module--mt-12--9c312";
export var mt120 = "buildingBold-module--mt-120--d04c8";
export var mt130 = "buildingBold-module--mt-130--aa2c3";
export var mt140 = "buildingBold-module--mt-140--0fb09";
export var mt15 = "buildingBold-module--mt-15--ac171";
export var mt150 = "buildingBold-module--mt-150--320af";
export var mt16 = "buildingBold-module--mt-16--7c704";
export var mt18 = "buildingBold-module--mt-18--07f6d";
export var mt185 = "buildingBold-module--mt-185--6fbb2";
export var mt20 = "buildingBold-module--mt-20--ab9fd";
export var mt25 = "buildingBold-module--mt-25--f3173";
export var mt30 = "buildingBold-module--mt-30--5600f";
export var mt35 = "buildingBold-module--mt-35--dca6a";
export var mt40 = "buildingBold-module--mt-40--f7a2e";
export var mt45 = "buildingBold-module--mt-45--d43a7";
export var mt48 = "buildingBold-module--mt-48--7cb01";
export var mt5 = "buildingBold-module--mt-5--8eeaf";
export var mt50 = "buildingBold-module--mt-50--8dece";
export var mt55 = "buildingBold-module--mt-55--073bb";
export var mt60 = "buildingBold-module--mt-60--7f6bc";
export var mt70 = "buildingBold-module--mt-70--08c55";
export var mt80 = "buildingBold-module--mt-80--ed500";
export var mt85 = "buildingBold-module--mt-85--cf391";
export var mt90 = "buildingBold-module--mt-90--b2dd8";
export var mt95 = "buildingBold-module--mt-95--23f25";
export var mv0 = "buildingBold-module--mv-0--42206";
export var mv10 = "buildingBold-module--mv-10--ed962";
export var mv100 = "buildingBold-module--mv-100--1876e";
export var mv105 = "buildingBold-module--mv-105--ab959";
export var mv110 = "buildingBold-module--mv-110--e4bd3";
export var mv115 = "buildingBold-module--mv-115--9d7f3";
export var mv12 = "buildingBold-module--mv-12--424bf";
export var mv120 = "buildingBold-module--mv-120--bd07b";
export var mv130 = "buildingBold-module--mv-130--0633c";
export var mv140 = "buildingBold-module--mv-140--0c24d";
export var mv15 = "buildingBold-module--mv-15--f5728";
export var mv150 = "buildingBold-module--mv-150--a6b8b";
export var mv16 = "buildingBold-module--mv-16--560d3";
export var mv18 = "buildingBold-module--mv-18--5c633";
export var mv185 = "buildingBold-module--mv-185--e4433";
export var mv20 = "buildingBold-module--mv-20--a2e05";
export var mv25 = "buildingBold-module--mv-25--f7f7f";
export var mv30 = "buildingBold-module--mv-30--f53de";
export var mv35 = "buildingBold-module--mv-35--91acc";
export var mv40 = "buildingBold-module--mv-40--5b330";
export var mv45 = "buildingBold-module--mv-45--1b3d1";
export var mv48 = "buildingBold-module--mv-48--632c3";
export var mv5 = "buildingBold-module--mv-5--c8622";
export var mv50 = "buildingBold-module--mv-50--6881e";
export var mv55 = "buildingBold-module--mv-55--8aaf6";
export var mv60 = "buildingBold-module--mv-60--d82f4";
export var mv70 = "buildingBold-module--mv-70--53376";
export var mv80 = "buildingBold-module--mv-80--3c7de";
export var mv85 = "buildingBold-module--mv-85--a222d";
export var mv90 = "buildingBold-module--mv-90--d5185";
export var mv95 = "buildingBold-module--mv-95--6c90f";
export var pa0 = "buildingBold-module--pa-0--4ad99";
export var pa10 = "buildingBold-module--pa-10--20f3c";
export var pa100 = "buildingBold-module--pa-100--e2b82";
export var pa105 = "buildingBold-module--pa-105--20851";
export var pa110 = "buildingBold-module--pa-110--bc2de";
export var pa115 = "buildingBold-module--pa-115--2c999";
export var pa12 = "buildingBold-module--pa-12--c87c0";
export var pa120 = "buildingBold-module--pa-120--b329e";
export var pa130 = "buildingBold-module--pa-130--0db4f";
export var pa140 = "buildingBold-module--pa-140--750e2";
export var pa15 = "buildingBold-module--pa-15--33b02";
export var pa150 = "buildingBold-module--pa-150--4c240";
export var pa16 = "buildingBold-module--pa-16--12cee";
export var pa18 = "buildingBold-module--pa-18--d91ed";
export var pa185 = "buildingBold-module--pa-185--02f36";
export var pa20 = "buildingBold-module--pa-20--2e41c";
export var pa25 = "buildingBold-module--pa-25--41f6b";
export var pa30 = "buildingBold-module--pa-30--0ca19";
export var pa35 = "buildingBold-module--pa-35--db648";
export var pa40 = "buildingBold-module--pa-40--f78a7";
export var pa45 = "buildingBold-module--pa-45--da6c9";
export var pa48 = "buildingBold-module--pa-48--801af";
export var pa5 = "buildingBold-module--pa-5--17d67";
export var pa50 = "buildingBold-module--pa-50--eec1b";
export var pa55 = "buildingBold-module--pa-55--d6988";
export var pa60 = "buildingBold-module--pa-60--c8cbf";
export var pa70 = "buildingBold-module--pa-70--7c510";
export var pa80 = "buildingBold-module--pa-80--9ae4b";
export var pa85 = "buildingBold-module--pa-85--99643";
export var pa90 = "buildingBold-module--pa-90--f70f3";
export var pa95 = "buildingBold-module--pa-95--b6a8a";
export var pb0 = "buildingBold-module--pb-0--dbad7";
export var pb10 = "buildingBold-module--pb-10--b4fd9";
export var pb100 = "buildingBold-module--pb-100--59fe0";
export var pb105 = "buildingBold-module--pb-105--7d12e";
export var pb110 = "buildingBold-module--pb-110--86915";
export var pb115 = "buildingBold-module--pb-115--d74b2";
export var pb12 = "buildingBold-module--pb-12--e6f70";
export var pb120 = "buildingBold-module--pb-120--2eca2";
export var pb130 = "buildingBold-module--pb-130--d5677";
export var pb140 = "buildingBold-module--pb-140--14dd9";
export var pb15 = "buildingBold-module--pb-15--cbeb2";
export var pb150 = "buildingBold-module--pb-150--852ab";
export var pb16 = "buildingBold-module--pb-16--d2c63";
export var pb18 = "buildingBold-module--pb-18--1b55b";
export var pb185 = "buildingBold-module--pb-185--34f29";
export var pb20 = "buildingBold-module--pb-20--b0b49";
export var pb25 = "buildingBold-module--pb-25--1aef9";
export var pb30 = "buildingBold-module--pb-30--87a14";
export var pb35 = "buildingBold-module--pb-35--2480b";
export var pb40 = "buildingBold-module--pb-40--4416e";
export var pb45 = "buildingBold-module--pb-45--5fa7b";
export var pb48 = "buildingBold-module--pb-48--9f635";
export var pb5 = "buildingBold-module--pb-5--bf17f";
export var pb50 = "buildingBold-module--pb-50--3811f";
export var pb55 = "buildingBold-module--pb-55--0da31";
export var pb60 = "buildingBold-module--pb-60--17aa3";
export var pb70 = "buildingBold-module--pb-70--0c38e";
export var pb80 = "buildingBold-module--pb-80--ef279";
export var pb85 = "buildingBold-module--pb-85--63cb0";
export var pb90 = "buildingBold-module--pb-90--a68df";
export var pb95 = "buildingBold-module--pb-95--78138";
export var ph0 = "buildingBold-module--ph-0--91b51";
export var ph10 = "buildingBold-module--ph-10--c6a75";
export var ph100 = "buildingBold-module--ph-100--911d6";
export var ph105 = "buildingBold-module--ph-105--3f796";
export var ph110 = "buildingBold-module--ph-110--bfd19";
export var ph115 = "buildingBold-module--ph-115--0c250";
export var ph12 = "buildingBold-module--ph-12--19dde";
export var ph120 = "buildingBold-module--ph-120--a3bb6";
export var ph130 = "buildingBold-module--ph-130--2ee87";
export var ph140 = "buildingBold-module--ph-140--03c3f";
export var ph15 = "buildingBold-module--ph-15--9cdf0";
export var ph150 = "buildingBold-module--ph-150--357e0";
export var ph16 = "buildingBold-module--ph-16--57d4c";
export var ph18 = "buildingBold-module--ph-18--a7436";
export var ph185 = "buildingBold-module--ph-185--dd66e";
export var ph20 = "buildingBold-module--ph-20--ae712";
export var ph25 = "buildingBold-module--ph-25--342ba";
export var ph30 = "buildingBold-module--ph-30--ce7ed";
export var ph35 = "buildingBold-module--ph-35--2a1d8";
export var ph40 = "buildingBold-module--ph-40--f72a6";
export var ph45 = "buildingBold-module--ph-45--c1cd8";
export var ph48 = "buildingBold-module--ph-48--6bdb8";
export var ph5 = "buildingBold-module--ph-5--5d071";
export var ph50 = "buildingBold-module--ph-50--83b65";
export var ph55 = "buildingBold-module--ph-55--c5954";
export var ph60 = "buildingBold-module--ph-60--2902e";
export var ph70 = "buildingBold-module--ph-70--de85e";
export var ph80 = "buildingBold-module--ph-80--a6691";
export var ph85 = "buildingBold-module--ph-85--1ab86";
export var ph90 = "buildingBold-module--ph-90--0dd36";
export var ph95 = "buildingBold-module--ph-95--1d605";
export var pl0 = "buildingBold-module--pl-0--dbf96";
export var pl10 = "buildingBold-module--pl-10--108b9";
export var pl100 = "buildingBold-module--pl-100--fbb4c";
export var pl105 = "buildingBold-module--pl-105--e6a8e";
export var pl110 = "buildingBold-module--pl-110--57a3f";
export var pl115 = "buildingBold-module--pl-115--6cc4d";
export var pl12 = "buildingBold-module--pl-12--b437d";
export var pl120 = "buildingBold-module--pl-120--c3197";
export var pl130 = "buildingBold-module--pl-130--098be";
export var pl140 = "buildingBold-module--pl-140--2e754";
export var pl15 = "buildingBold-module--pl-15--e44a8";
export var pl150 = "buildingBold-module--pl-150--d6506";
export var pl16 = "buildingBold-module--pl-16--edc32";
export var pl18 = "buildingBold-module--pl-18--b9650";
export var pl185 = "buildingBold-module--pl-185--decaa";
export var pl20 = "buildingBold-module--pl-20--9af62";
export var pl25 = "buildingBold-module--pl-25--fdc1c";
export var pl30 = "buildingBold-module--pl-30--fd9bf";
export var pl35 = "buildingBold-module--pl-35--8cc02";
export var pl40 = "buildingBold-module--pl-40--017c4";
export var pl45 = "buildingBold-module--pl-45--fd455";
export var pl48 = "buildingBold-module--pl-48--8feac";
export var pl5 = "buildingBold-module--pl-5--8205f";
export var pl50 = "buildingBold-module--pl-50--cfd7a";
export var pl55 = "buildingBold-module--pl-55--ee53c";
export var pl60 = "buildingBold-module--pl-60--29765";
export var pl70 = "buildingBold-module--pl-70--ba164";
export var pl80 = "buildingBold-module--pl-80--8f12e";
export var pl85 = "buildingBold-module--pl-85--50016";
export var pl90 = "buildingBold-module--pl-90--0bdb7";
export var pl95 = "buildingBold-module--pl-95--11fee";
export var pr0 = "buildingBold-module--pr-0--b8b42";
export var pr10 = "buildingBold-module--pr-10--342eb";
export var pr100 = "buildingBold-module--pr-100--20499";
export var pr105 = "buildingBold-module--pr-105--ed574";
export var pr110 = "buildingBold-module--pr-110--8f6c0";
export var pr115 = "buildingBold-module--pr-115--9ebc0";
export var pr12 = "buildingBold-module--pr-12--fb127";
export var pr120 = "buildingBold-module--pr-120--3cd9a";
export var pr130 = "buildingBold-module--pr-130--a812c";
export var pr140 = "buildingBold-module--pr-140--41895";
export var pr15 = "buildingBold-module--pr-15--04c01";
export var pr150 = "buildingBold-module--pr-150--42f4c";
export var pr16 = "buildingBold-module--pr-16--f7cd9";
export var pr18 = "buildingBold-module--pr-18--9a94d";
export var pr185 = "buildingBold-module--pr-185--8a1ff";
export var pr20 = "buildingBold-module--pr-20--19869";
export var pr25 = "buildingBold-module--pr-25--cd999";
export var pr30 = "buildingBold-module--pr-30--040f0";
export var pr35 = "buildingBold-module--pr-35--908c7";
export var pr40 = "buildingBold-module--pr-40--25c25";
export var pr45 = "buildingBold-module--pr-45--79be9";
export var pr48 = "buildingBold-module--pr-48--723a3";
export var pr5 = "buildingBold-module--pr-5--5e199";
export var pr50 = "buildingBold-module--pr-50--3e226";
export var pr55 = "buildingBold-module--pr-55--90abd";
export var pr60 = "buildingBold-module--pr-60--9c545";
export var pr70 = "buildingBold-module--pr-70--83ed2";
export var pr80 = "buildingBold-module--pr-80--040f6";
export var pr85 = "buildingBold-module--pr-85--1fd43";
export var pr90 = "buildingBold-module--pr-90--b5bb1";
export var pr95 = "buildingBold-module--pr-95--a4f69";
export var pt0 = "buildingBold-module--pt-0--33e69";
export var pt10 = "buildingBold-module--pt-10--afe8a";
export var pt100 = "buildingBold-module--pt-100--40d7e";
export var pt105 = "buildingBold-module--pt-105--334cf";
export var pt110 = "buildingBold-module--pt-110--49a22";
export var pt115 = "buildingBold-module--pt-115--ff799";
export var pt12 = "buildingBold-module--pt-12--6374d";
export var pt120 = "buildingBold-module--pt-120--19cc6";
export var pt130 = "buildingBold-module--pt-130--5b2b6";
export var pt140 = "buildingBold-module--pt-140--f7def";
export var pt15 = "buildingBold-module--pt-15--0ed83";
export var pt150 = "buildingBold-module--pt-150--e9a4c";
export var pt16 = "buildingBold-module--pt-16--9a6df";
export var pt18 = "buildingBold-module--pt-18--ddc6a";
export var pt185 = "buildingBold-module--pt-185--c49ad";
export var pt20 = "buildingBold-module--pt-20--5c740";
export var pt25 = "buildingBold-module--pt-25--a1f4b";
export var pt30 = "buildingBold-module--pt-30--e9be7";
export var pt35 = "buildingBold-module--pt-35--a27b4";
export var pt40 = "buildingBold-module--pt-40--c539a";
export var pt45 = "buildingBold-module--pt-45--2af04";
export var pt48 = "buildingBold-module--pt-48--448f5";
export var pt5 = "buildingBold-module--pt-5--f892f";
export var pt50 = "buildingBold-module--pt-50--5332a";
export var pt55 = "buildingBold-module--pt-55--4d256";
export var pt60 = "buildingBold-module--pt-60--46322";
export var pt70 = "buildingBold-module--pt-70--a3b8b";
export var pt80 = "buildingBold-module--pt-80--2c3f0";
export var pt85 = "buildingBold-module--pt-85--31388";
export var pt90 = "buildingBold-module--pt-90--bb8df";
export var pt95 = "buildingBold-module--pt-95--3e053";
export var pv0 = "buildingBold-module--pv-0--11e93";
export var pv10 = "buildingBold-module--pv-10--09cde";
export var pv100 = "buildingBold-module--pv-100--65d15";
export var pv105 = "buildingBold-module--pv-105--95c08";
export var pv110 = "buildingBold-module--pv-110--df583";
export var pv115 = "buildingBold-module--pv-115--875fd";
export var pv12 = "buildingBold-module--pv-12--49ccd";
export var pv120 = "buildingBold-module--pv-120--e26da";
export var pv130 = "buildingBold-module--pv-130--d345e";
export var pv140 = "buildingBold-module--pv-140--3766a";
export var pv15 = "buildingBold-module--pv-15--380f5";
export var pv150 = "buildingBold-module--pv-150--28686";
export var pv16 = "buildingBold-module--pv-16--60be5";
export var pv18 = "buildingBold-module--pv-18--2acd0";
export var pv185 = "buildingBold-module--pv-185--d35e8";
export var pv20 = "buildingBold-module--pv-20--e9172";
export var pv25 = "buildingBold-module--pv-25--1c3ca";
export var pv30 = "buildingBold-module--pv-30--b836e";
export var pv35 = "buildingBold-module--pv-35--5d594";
export var pv40 = "buildingBold-module--pv-40--3f19b";
export var pv45 = "buildingBold-module--pv-45--766c9";
export var pv48 = "buildingBold-module--pv-48--24485";
export var pv5 = "buildingBold-module--pv-5--d7722";
export var pv50 = "buildingBold-module--pv-50--93879";
export var pv55 = "buildingBold-module--pv-55--11b6f";
export var pv60 = "buildingBold-module--pv-60--d35c6";
export var pv70 = "buildingBold-module--pv-70--9fd63";
export var pv80 = "buildingBold-module--pv-80--87571";
export var pv85 = "buildingBold-module--pv-85--3b436";
export var pv90 = "buildingBold-module--pv-90--9bb94";
export var pv95 = "buildingBold-module--pv-95--5b852";
export var textWrapper = "buildingBold-module--textWrapper--68567";
export var wrapper = "buildingBold-module--wrapper--053e7";
export var zoomed = "buildingBold-module--zoomed--61a7b";